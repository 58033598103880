.buttonPC {
     padding-left: 0px;
     padding-right: 5px;
     width: 25px;
     height: 17px;
     box-sizing: border-box;
     ;
}
 
.buttonGooleDriveGet {
     background-image: url(../img/googledriveIcon.png);
     background-repeat: no-repeat;
     background-color: #FFF;
     background-position-x: left;
     background-position-y: center;
     background-size: 25px;
     min-width: 25px;
     padding-left: 25px !important;
     font-size: 15px;
     border: 0px;
     opacity: 1;
     color: #878787;
     filter: alpha(opacity=1);
}

.buttonGooleDriveGet:hover {
     background-image: url(../img/googledriveIconHover.png);
     /*font-size: 16px;
     /*color:#E55200;*/
     background-color: #878787;
     color: #FFF;
}

.buttonDropBoxGet {
     background-image: url(../img/DropboxIcon.png);
     background-repeat: no-repeat;
     background-color: #FFF;
     background-position-x: left;
     background-position-y: center;
     background-size: 25px;
     min-width: 25px;
     padding-left: 25px !important;
     font-size: 15px;
     border: 0px;
     color: #878787;
}

.buttonDropBoxGet:hover {
     background-image: url(../img/DropboxIconHover.png);
     /*color:#E55200;*/
     color: #FFF;
}

.contentCloudIconsModal {
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 200px;
     min-height: 300px;
     background: #FFFFFF 0% 0% no-repeat padding-box;
     box-shadow: 0px 5px 5px #00000080;
     border: 0.5718113780021667px solid #E3AC31;
     border-radius: 6px;
     opacity: 1;
     padding: 20px;
     box-sizing: border-box;
}

.contentCloudModal {
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 800px;
     min-height: 440px;
     background: #FFFFFF 0% 0% no-repeat padding-box;
     box-shadow: 0px 5px 5px #00000080;
     border: 0.5718113780021667px solid #E3AC31;
     border-radius: 6px;
     opacity: 1;
     padding: 20px;
     box-sizing: border-box;
}

.buttonOneDriveGet {
     background-image: url(../img/OnedriveIcon.png);
     background-repeat: no-repeat;
     background-color: #FFF;
     background-position-x: left;
     background-position-y: center;
     background-size: 50px!important;
     background-size: 25px !important;
     min-width: 25px !important;
     padding-left: 25px !important;
     font-size: 15px !important;
     ;
     font-weight: lighter !important;
     border: 0px;
     color: #878787 !important;
     text-transform: none !important;
     ;
}

.buttonOneDriveGet:hover {
     background-image: url(../img/OnedriveIconHover.png);
     color: #FFF !important;
}

.buttonOneDrivePut {
     margin: 2px;
     background-image: url(../img/OnedriveIcon.png);
     background-repeat: no-repeat;
     background-position: center;
     background-size: 25px!important;
     background-size: 25px !important;
     width: 32px !important; 
     height: 32px !important;
}

.buttonOneDrivePut:hover {
     background-image: url(../img/OnedriveIconHover.png);
     color: #FFF !important;
}

.itemOneDriveExplorer {}

.itemOneDriveExplorer:hover {
     background-color: #e0e0e0;
}

.itemOneDriveExplorerActive {}

.contentExplorerOneDrive {
     border: 1px solid #a49b99;
     max-height: 400px;
     overflow-y: scroll;
}

.menuSourceFile li {
     color: #878787;
}

.menuSourceFile li:hover, .menuSourceFile li:hover button {
     background-color: #878787;
     color: #FFF;
}