@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&display=swap");
body {
  font-family: "adobe-clean", sans-serif;
}

.dragme {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 270px;
  height: 203px;
  cursor: move;
}

.drag-container {
  position: relative;
  text-align: center;
  overflow: auto;
  border: 1px solid;
  border-radius: 5px;
  background-color: #ededed;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

#draggable {
  background-color: #ccc;
  border: 1px solid #000;
  box-shadow: 0 2px 4px 0 rgba(156, 148, 148, 0.5);
}

.pdf-container-workarea {
  padding-top: 0px !important;
}

.pdf-botonera-firmas {
  padding-top: 0px;
  width: 90%;
  max-height: 60px;
}
.gridBotonera {
  grid-gap: 7px;
  flex-direction: row;
}
.pdf-botonera-btn {
  background: #ededed 0% 0% no-repeat padding-box;
  padding-top: 7px;
  border: 1px solid #989898;
  font: normal normal 100 14px/14px Muli;
  color: #4f4f4f !important;
  border-radius: 10px;
  opacity: 1;
  min-height: 42px;
  margin: 0px 20px 5px 0px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: auto;
}

.pdf-botonera-btn-active {
  background: #f5e9d0 0% 0% no-repeat padding-box;
  padding-top: 7px;
  border: 1px solid #f5ae19;
  font: normal normal normal 14px/14px Muli;
  border-radius: 10px;
  opacity: 1;
  min-height: 42px;
  margin: 0px 20px 5px 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  color: #4f4f4f;
}

.pdf-botonera-btn_CF {
  background-color: #F3F3F3 !important;
  margin-bottom: 16px !important;
  text-transform: none !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 10px !important;
  width: "100%" !important;
  justify-content : flex-start !important;
  max-width: 300px !important;
  text-transform: initial !important;
}

.pdf-botonera-btn_CF-active {
  background-color: #F3F3F3!important;
    margin-bottom: 16px !important;
    text-transform: none !important;
    border-radius: 10px !important;
    width: 100% !important;
    justify-content: flex-start !important;
    max-width: 300px !important;
    border: 1px solid #F5AE19 !important;
}
@media screen and (max-width: 600px){
.pdf-botonera-btn_CF {
  font-size: 1rem !important;
  max-width: 100% !important;
  
}

.pdf-botonera-btn_CF-active {
    font-size: 1rem !important;

    max-width: 100% !important;
   
}
}
.pdf-btn {
  background: #e55200 !important;
  margin-top: 32px !important;
  font: normal normal 800 18px/22px Muli !important;
  min-width: 118px !important;
  box-sizing: border-box;
  text-transform: none !important;
  height: 42px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
}

.pdf-btn-default {
  color: #000000 !important;
  font-size: 11pt !important;
  font-weight: 600 !important;
  text-transform: none !important;
  height: 60px !important;
}

.pdf-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 95%;
  border: 1px solid #f5ae19 !important;
  margin-top: 0px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.pdf-container {
  height: 100%;
  padding: 20px;
  width: 100%;
}

.btn-close-sign-popup {
  padding: 0px !important;
  margin-top: -15px !important;
  margin-right: -15px !important;
  width: 20px !important;
  height: 20px !important;
}

.btn-close-sign-popup svg {
  width: 20px !important;
  height: 20px !important;
}

#btn-recipient-close {
  margin-top: 8px !important;
  margin-right: 8px !important;
  background-size: 90% !important;
  height: 2px !important;
}

.btn-close-sign-modal {
  background-image: url("../../../assets/img/icono-cerrar.svg");
  background-position: 0 0 !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
  width: 24px !important;
  height: 26px !important;
  margin-top: 4px !important;
  margin-right: 4px !important;
}

#forwardCode {
  margin-top: 7.5px !important;
  margin-left: 10.5px !important;
}

.btn-forward-sign-modal {
  background-image: url("../../../assets/img/icon_forward.svg");
  background-position: 0 0 !important;
  background-size: 60% !important;
  background-repeat: no-repeat;
  width: 24px !important;
  height: 26px !important;
  margin-top: 4px !important;
  margin-left: 5px !important;
}

.subtitleOptions {
  font: normal normal 600 16px/22px Muli !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #4f4f4f !important;
  opacity: 1;
}
#lineaProgresoTexto {
  font-size: 11px;
}
#lineaProgresoCaja {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pdf-miniaturas {
  height: 100%;
  margin-bottom: 0;
  background-color: #ededed;
  border-radius: 5px;
  border: #dadada 2px solid;
  border-top: 0px;
}
.pdf-miniaturasCF{
  max-height: 580px;
}

.pdf-miniaturas-firmas {
  margin-top: 7px;
  margin-bottom: 30px;
  overflow-y: auto;
  height: 90%;
  background-color: #ededed;
}

.pdf-miniaturas-firmasCF{
  /* margin-top: 7px; */
  height: auto;
  overflow-y: auto;
  max-height: 500px;
}

.sinMargin {
  margin-bottom: 0;
  margin-top: 3px;
  max-height: 625px;
  padding-bottom: 0;
}

.pdf-canvas-mini {
  display: block;
  border: 1px solid;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pdf-canvas-mini-p {
  text-align: center;
}

.pdf-canvas {
  /* width: 10%;
  max-width: 500px; */
  display: block;
  margin: 0 auto;
  border-top: 1px solid #CCCCCC ;
  border-bottom: 1px solid #CCCCCC;
  /*overflow:auto;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.div-canvas {
  position: relative;
  display: inline-block;
  /*overflow: auto;*/
}

.accordion {
  cursor: pointer;
  margin: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 85%;
  max-width: 240px;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  border: 0px !important;
  color: #4f4f4f;
  background-color: #c6c6c6;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234F4F4F%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
}
.accordion2 {
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  border: 0px !important;
  color: #4f4f4f;
  background-color: #c6c6c6;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234F4F4F%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  font-family: Mulish;
  font-size: 12px;
}

.accordion-firmas {
  position: relative;
  /*background-color: #eee;*/
  color: #444;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.accordion-firmas-img {
  width: 50px;
  background-color: white;
  height: auto;
  padding: 5px;
  border: 1px solid;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.accordion-firmas-img-close {
  cursor: pointer;
  top: 50%;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  font-size: 20pt;
}

.accordion-firmas-img-text {
  top: 50%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.accordion-firmas-img-close:hover {
  color: red;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active,
.accordion:hover {
  margin: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 0px !important;
  border: 0px #706f6f solid !important;
  width: 85%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #706f6f;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  max-width: 240px;
}
.active2,
.accordion2:hover {
  margin-bottom: 10px;
  border-width: 0px !important;
  border: 0px #706f6f solid !important;
  width: 100%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #706f6f;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 -3.49691e-07L8 6L0 6L4 -3.49691e-07Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
}

/* Style the accordion panel. Note: hidden by default */

.panel {
  padding: 0 18px;
  /*background-color: white;*/
  display: none;
  overflow: hidden;
}

#loadingPDF {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../../../assets/img/loader.gif");
  /*url("https://i.stack.imgur.com/MnyxU.gif");*/
  background-repeat: no-repeat;
  background-position: center;
}

.pdf-alert {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 5px 5px #00000080;
  border: 1px solid lightgray;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
}

.pdf-alert-title {
  text-align: center;
  letter-spacing: 0px;
  color: #e55200;
  opacity: 1;
  font-size: 19px;
}

.zoom-button {
  border: 0px;
  border-right: 1px solid #9d9d9c;
  padding-right: 5px !important;
}

.addDocumentToSign {
  background: #ededed;
  padding: 0px !important;
  /*border: #DADADA 2px solid;*/
  border-bottom: 0px !important;
  margin-bottom: -5px !important;
}

.addDocumentToSign button {
  background: #ffffff;
  padding: 0px !important;
  margin-top: 5px;
}

.mini-container {
  height: 100%;
  width: 20%;
}

.fullContainer {
  height: 100%;
  max-height: 560px;
  width: 100%;
}

.botonera-container {
  width: 80%;
  height: 100%;
  padding-left: 15px;
}

.box-botonera {
  display: flex;
  padding-top: 11px;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.pdf-botonera-siguiente {
  width: 10%;
  height: 2%;
}

.pdf-btn-float {
  background: #f5ae19 !important;
  padding: 20px !important;
  color: #ffffff !important;
  font: bold 17px Muli !important;
  text-transform: none !important;
  text-shadow: 2px 3px 0px #00000040 !important;
  width: 160px !important;
  height: 49px !important;
  border-radius: 7px !important;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 0px #00000040;
  visibility: hidden;
}

.editar-container {
  height: 20px;
  width: 100% !important;
  padding: 0px;
}

.box-signer {
  display: none !important;
  position: fixed;
  visibility: visible;
  top: 35%;
  right: 0;
  transform: translate(-55%, 0);
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  background: #f5ae19 !important;
  align-content: center;
  box-shadow: 0px 4px 5px #0000004d !important;
}

.box-signer button {
  display: flex;
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url("../../../assets/img/iconFirmantes.png") no-repeat center
    center;
}

.bar-sign {
  display: none !important;
  display: flex;
  flex-direction: row;
  color: #e55200;
}

.bar-sign button {
  padding: 0;
  height: 24px;
  width: auto;
  margin: 15px 15px 0px 15px;
}

.bar-sign h3 {
  margin: 15px 5px 0px 10px;
}

.nameQR::before {
  content: "Código ";
}

.view-container {
  height: 75% !important;
}
.circuito {
  height: 100% !important;
}

@supports (object-fit: cover) {
  .box-signer button {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
@media screen and (max-width: 1279px){
.fullContainer{
  display: none;
}
}
@media screen and (max-width: 1252px) {
  .pdf-paper {
    width: 100%;
    height: 100%;
    border: none !important;
    margin-top: 0px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
  }
  .nameQR::before {
    content: "";
  }
  .pdf-container {
    padding: 0;
  }
  .mini-container {
    display: none !important;
  }
  .botonera-container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 0;
  }
  .pdf-botonera-firmas {
    width: 100%;
  }
  .pdf-botonera-siguiente {
    display: none !important;
  }
  .editar-container {
    display: none !important;
  }
  .drag-container {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .subtitleOptions {
    display: none;
  }
  .pdf-btn-float {
    position: fixed;
    visibility: visible;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .box-botonera {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .gridBotonera {
    grid-gap: 0px;
    flex-direction: column;
  }
  .pdf-botonera-btn {
    width: 112px;
    padding-top: 10px;
    height: 60.19px;
    font-size: 11px;
  }

  .pdf-botonera-btn-active {
    width: 112px;
    padding-top: 10px;
    height: 60.19px;
    font-size: 11px;
  }
  

  .box-signer {
    display: block !important;
  }
  .bar-sign {
    display: flex !important;
    margin: 0;
  }
  .view-container {
    margin-top: 18px !important;
    height: 65% !important;
  }
  .pdf-botonera-firmas {
    max-height: 115px !important;
  }
}

@media screen and (max-width: 991px) {
  .pdf-botonera-firmas {
    padding-top: 5px;
    height: auto !important;
  }
  .pdf-botonera-btn-active {
    margin-left: auto;
    margin-right: auto;
  }
  .pdf-botonera-btn {
    margin-left: auto;
    margin-right: auto;
  }
}