/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-mar-2020, 16:50:42
    Author     : jose.ochoa
*/
body
{
    background-color: #FFF;

}
.BarHeader
{
    background: #F1F1F1 0% 0% no-repeat padding-box;
    align-content: center;
    height: 60px;
    width: 100%;
}
.BarHeader .content
{
    width: 70%;
    min-width: 944;
    margin: auto !important; 
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    /**background: url("../resources/logoGSE.png");**/
    background-position: right;
    background-repeat: no-repeat;
}

.Header img
{
   width: 100%;
   height: 180px;
}

.tableInfo {
    width: 100%;
    table-layout: fixed;
    font-family: arial, sans-serif;
    margin: 0 30px 0 30px;
    border-collapse: collapse;
}
  
.tableInfo td, th {    
    word-break: break-all;
    text-align: justify;
    padding: 8px;
}

.tableInfo th{
    background: #FFEFD8 0% 0% no-repeat !important;
    opacity: 1;    
    font: normal normal bold 11px/16px Muli;
    letter-spacing: 0px;
    text-transform: capitalize;
    color: #E55200;
    border-spacing: 0px !important;
    border: 1px solid black;
    max-height: 32px !important;
    box-sizing: border-box !important;
    padding: 8px !important;
}

.tableInfo td{
    background: white 0% 0% no-repeat !important;    
    opacity: 1;    
    font: normal normal bold 11px/16px Muli;
    letter-spacing: 0px;
    color: black;
    border-spacing: 0px !important;
    border: 1px solid black; 
    max-height: 32px !important;
    box-sizing: border-box !important;
    padding: 8px !important;
}

.contentPage
{
    width: 100%;
   /**background: #fFF url("../resources/bannerfirmaya3.png"); **/
   background-size: cover;
   background-repeat: no-repeat;
    background-position: 0% 00%;   
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mainTitle
{
    height: 120px;
    background: #000000 0% 0% no-repeat padding-box;
    mix-blend-mode: normal;
    opacity: 0.3;
    

}
.textTitle
{
    position: absolute;
    top: 75px;
    left: 15%;
    height: 41px;
    width: 70%;
    margin: auto !important;     
    color: #FFFFFF  !important;    
    font-size: 33px;
    padding-top: 10px;    
    box-sizing: border-box;
}
.contentView
{
    width: 100%;
    color: #a49b99;
    font-size: 1rem;
    padding: 15px;
}
.contentHeadText
{
    width: 70%;
    min-width: 500px;
    margin: auto !important; 
}

.contentViewHeader
{
    width: 70%;
    margin: auto !important; 
    color: #a49b99;
    font-size: 1rem;
    padding: 20px;
    box-sizing: border-box;
    background-color: #FFF;
    border:1px solid #F5AE19;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    top:-40px;
    min-height: 600px;
    min-width: 277px;
}
@media (max-width: 350px) {
    .contentViewHeader {
        width: 100%; /* Cambia el ancho para pantallas más pequeñas */
    }
}
.contentView h3{
     color: #a49b99;
     font-size: 1.8rem;
}

.form-control {    
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

.contentCapcha
{
    min-width:233;
    margin-bottom: 10px;
}

#captcha
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border:1px solid  #eeeeee;
    background-color: #FFF;;
} 
.divContentText
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #eeeeee;
    padding: 20px;
    box-sizing: border-box;
} 
.btnClass
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: #FFF;
    font-size: 18px;
    padding: 10px;   
    background-color: #F5AE19;
    border: #f5ae19 2px solid ;
} 
.btnClass:hover
{
    background-color: #e46219;
} 

.contentPDF
{
    align-content: center;
    width: 100%;
    min-width: 824px;
    margin: 20px;
 
} 

@media screen and (max-width: 1488px) {
    .captchaMin {
        display: flex;
        gap: 20px;
    }   
} 

@media screen and (max-width: 750px) {

    .contentViewHeader {
        top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contentHeadText {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contentHeadTexts {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}