@import url(https://fonts.googleapis.com/css?family=Muli|Roboto&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&family=Noto+Emoji&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&display=swap);
body {
 /* background-image: url(assets/img/fondo_login.jpg);
  background-size:'100%';
  background-repeat: no-repeat;*/
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bodyMiddle {
  background: url(/static/media/fondo_login.1f5d40cf.png) no-repeat top right;
  z-index: -1;
}

.bodyMiddle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  height: 161px;
  z-index: -1;
}

.bodyFull {
  background-image: url(/static/media/fondo_login.1f5d40cf.png);
  background-position: 0 0 !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
}

.MuiToolbar-root {
  padding: 0px !important;
}

.MuiAvatar-colorDefault {
  background: #9D9D9C 0% 0% no-repeat padding-box !important;
  color: #FFF !important;
  font-size: 20px !important;
  float: right;
}

.makeStyles-avatar-6:hover {
  background-color: #f1f1f1 !important;
}


 

.MuiContainer-root {
   width: 1350px !important;
  min-width: 300px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*@media  only screen and (min-width: 600px)
{
    .MuiContainer-root
    {
        width: 100% !important;
    }
    body,header, #app, .bodyMiddle
    {
            min-width: 1024px;
            background-size: 1024px 400px  !important;
     
    }


}*/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ACACAC;
  border-radius: 3px !important;
}

.textZoneUploadLocal {
  width: 100%;
  margin: 8px 0 25px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.gridValidate {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.gridCades{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex-direction: row !important;
  margin-top: 20px !important;
}
.paperTableValidate {
  width: 440px;
  padding: 20px 27px;
}

.subtitleRecipient {
  font: normal normal bold 20px/20px Muli;
  margin: 0 0 0 0;
  color: #E55200;
  padding-bottom: 20px;
  padding-top: 20px;
}

.textZoneUpload {
  width: 100%;
  margin: 8px 0 8px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.menuChange li {
  color: #878787;
}

.menuChange li:hover {
  background-color: #878787;
  color: #FFF;
}

.menuChange .MuiMenu-paper {
  width: 215px;
}

.paperValidate {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  padding-bottom: 10px !important;
  box-shadow: 0px 3px 3px #00000080 !important;
  border: 0.2860439717769623px solid #E3AC31 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  box-sizing: border-box !important;
}

#validate {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btnValidate {
  background-color: #F5AE19 !important;
  box-shadow: 0px 3px 0px #00000033 !important;
  width: 124px;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 44px;
  font: normal normal bold 16px/16px Muli !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  padding-right: 72px !important;
  padding-left: 72px !important;
  color: #FFFFFF !important;
  border: 1px solid #F5AE19 !important;
  ;
  text-transform: initial !important;
  ;
}
.btnFirmaCades {
  display: flex !important;
  flex-direction: row !important;
  background-color: #E55200 !important;
  color: #FFFFFF !important;
  padding: 8px 24px !important;
  font-size: 16px !important;
  width: 124px;
  height: 44px;
  text-transform: initial !important;
  border-radius: 8px !important;
}
#clearRecipient {
  text-shadow: none;
  width: 168px;
  height: 41px;
  font: normal normal bold 16px/22px Muli !important;
}

.btnChange {
  background-color: #FFFFFF !important;
  letter-spacing: 1px;
  text-shadow: 1px 0 #F5AE19;
  opacity: 1 !important;
  height: 45px;
  font: normal normal bold 18px/18px Muli !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #F5AE19 !important;
  border: 1px solid #F5AE19 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
}
.btnChange2 {
  background-color: #FFFFFF !important;
  border-radius: 10px !important;
  border: 1px solid #878787 !important;
  color: #878787 !important;
  font-family: Mulish !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  height: 20px;
  line-height: 16px;
  opacity: 1 !important;
  width: 117px;
  text-transform: capitalize !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
}

.textZoneUploadSign {
  text-align: center;
  font: normal normal normal 18px/22px Muli;
  letter-spacing: 0px;
  color: #9D9D9C;
  opacity: 1;
}

.btnPut {
  width: 124px !important;
  font: normal normal bolder 15px/0px Muli !important;
  height: 27px !important;
  border-radius: 7px !important;
  opacity: 1 !important;
  color: #F5AE19 !important;
  border: 1px solid #E3AC31 !important;
  text-transform: initial !important;
  margin: 7px !important;
}

.textZoneUploadO {
  margin: -8px 0 5px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.textZoneUpload span {
  color: #E55200;
  font: normal normal bold 18px/22px Muli;
  letter-spacing: 0px;
}

.iconEncrypt {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
}

.iconEncryptColor {
  background-image: url(/static/media/iconCifrar.c3e6170c.svg)
}

.iconEncryptColor:hover,
.iconEncryptColor:focus {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/icon-bike-red.svg) !important;
}

.btnGeneral {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}
.btnGeneralNew {
  background-color: #FFFFFF !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  opacity: 1;
  color: #E55200 !important;
  /* text-shadow: 0px 2px 0px #00000040 !important; */
  border: none !important;
  
  padding: 9px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: Muli !important;
  border: 1px solid #E55200 !important;	

}

.btnGeneralNewDos {
  background-color: #FFFFFF !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  opacity: 1;
  border: none !important;
  color: #444444 !important;
  padding: 9px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: Muli !important;
  border: 1px solid #706F6F !important;	
}

.orangeIcon {
  color: #f5ae19; 
}

#buttonRecipientBlock:disabled {
  opacity: 0.19
}

.buttonRecipient {
  background-color: #F5AE19 !important;
  box-shadow: 0px 3px 0px #00000033;
  height: 38px;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  padding-left: 16px !important;
  padding-right: 15px !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  font: normal normal bold 16px/22px Muli !important;
  letter-spacing: 0px;
  text-transform: initial !important;
}

.buttonSearchRecipient:disabled {
  opacity: 0.19
}

.buttonSearchRecipient {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  margin-left: 2px !important;
  padding: 0px !important;
  box-shadow: 0px 3px 0px #00000033 !important;
  min-width: 36px !important;
  height: 36px;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  letter-spacing: 0px;
}

.btnPopup {
  background-color: #F5AE19 !important;
  display: none !important;
  letter-spacing: 0.8px;
  margin-top: 7px !important;
  margin-bottom: 29px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  height: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

#apply {
  display: initial !important;
}

.gridDocuments {
  padding-left: 4px;
  padding-right: 10px
}

.btnGeneralResize {
  background-color: #F5AE19 !important;
  margin-bottom: 49px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-left: 5px !important;
}

.btnGeneralSign:disabled,
button[disabled] {
  opacity: 0.4;
}

.tabItems {
  padding-left: 10 !important;
  padding-right: 10 !important;
  min-width: 184px !important;
  text-transform: none !important;
  min-height: 64px !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #4F4F4F !important;
}

.tabItems:hover {
  background: transparent linear-gradient(180deg, #DADADA00 1%, #DADADA 100%) 0% 0% no-repeat padding-box;
  border-bottom: 3px solid #E55200;
  color: #E55200,
}

.btnGeneral18 {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 38px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  margin-top: 14px !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnsMenuMessage {
  padding: 0 !important;
  background-color: transparent !important;
  transition: 0.2s !important;
}

.disabledToolbar {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.wraper {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wraperInput {
  appearance: none;
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  background-color: #ffffff;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid grey;

}

.wraperInput:after {
  font-weight: 900;
  content: "\2714";
  display: none;
}

.wraperInput:checked:after {
  display: block;
  color: orangered;
}

.wraperLabel {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 6020;
  cursor: pointer;
}

.btnContinue {
  background-color: #E55200 !important;
  background: #E55200 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  padding: 5px 25px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  margin-top: 14px !important;
  /* font-weight: bold !important; */
  font-size: 16px !important;
}

.btnContinue:hover {
  background-color: #db4f04 !important;
}

.btnContinue2 {
  -webkit-user-select: none;
          user-select: none;
  cursor: none;
  display: none;
  opacity: 0;

}

.messageCircuitoF {
  border: 2px solid gray;
  border-radius: 5px;
}

.checkboxFirmantes {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
}

.checkboxFirmantes__input {
  display: none;
}

.checkboxFirmantes__box {
  width: 14px;
  height: 14px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: -5px;
  flex-shrink: 0;
  background-color: white;
}

.checkboxFirmantes__input:checked+.checkboxFirmantes__box::before {
  content: "\2714";
  /* content: "✔"; */
  color: orangered;
  font-size: "small";

}


.btnAdd {
  background-color: white !important;
  background: white 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #E55200 !important;
  ;
  border: 1px solid #E55200 !important;
  ;
  padding: 5px 25px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  margin-top: 14px !important;
  font-size: 16px !important;
}

.listDocuments {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAAXNSR0IArs4c6QAAAFJJREFUSEvt1jEKADAIA8D4Ff//L7/gltLOxUGc2ji5CUcUjSQBICKQmbuFu9tphss0bEJUjBOKEKMYSwEFRAFRQPQW9LfgswvSh6p/zivjE8MWFDgOK4G2HZkAAAAASUVORK5CYII=);
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Mulish;

}
.ulCF{
  margin: "0px"; 
  overflow: "auto"; 
  list-style:"none"; max-width:"100%";
  }
  
.listDocumentsCF{
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAAXNSR0IArs4c6QAAAFJJREFUSEvt1jEKADAIA8D4Ff//L7/gltLOxUGc2ji5CUcUjSQBICKQmbuFu9tphss0bEJUjBOKEKMYSwEFRAFRQPQW9LfgswvSh6p/zivjE8MWFDgOK4G2HZkAAAAASUVORK5CYII=);
  width: 100%;
  white-space: nowrap;
  list-style-position: inside;
  margin-left: -35px;
  height: 20px;
  font-family: Mulish;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
.btnSubmitPassword {
  background-color: #E55200 !important;
  background: #E55200 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 38px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

#buttonVerify {
  margin-top: 16px !important
}

.btnGeneral18:disabled,
button[disabled] {
  opacity: 0.19;
}

.btnGeneral:disabled,
button[disabled] {
  opacity: 0.4;
}

.btnGeneralSignIcon {
  background-color: #F5AE19 !important;
  margin-top: 10px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 19px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneralSign {
  background-color: #F5AE19 !important;
  margin-top: 10px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.appBar {
  border-bottom: 3px solid #b27947 !important;
}

.verticalLine {
  border: 1px solid #D6D6D6;
  opacity: 1;
  width: 0px;
  height: 25px;
  margin-left: 20px !important;
}

.verticalLine1 {
  border: 1px solid #D6D6D6;
  opacity: 1;
  width: 0px;
  height: 25px;
  margin-left: 30px !important;
}

.titleDocumentsHome {
  font: normal normal bold 24px/30px Muli !important;
  margin-top: 20px !important;
  color: #E55200;
  letter-spacing: 0px !important;
  margin-left: 2% !important;
  margin-bottom: 0% !important
}
.subtitleDocumentsHome {
  /* font: normal normal bold 24px/30px Muli !important; */
  margin-top: 20px !important;

  letter-spacing: 0px !important;
  margin-left: 2.5% !important;
  margin-bottom: 0% !important
}
@media (max-width: 600px) {
  .titleDocumentsHome {
    font-size: 18px !important;
     margin-left: 2% !important;
  }
}

.titleDocuments {
  font: normal normal bold 20px/20px Muli !important;
  margin-top: 20px !important;
  color: #E55200;
  letter-spacing: 0px !important;
  margin-left: 2% !important;
  margin-bottom: 3% !important
}

.btnDocuments {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  /*background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 28px;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 6px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  font: normal normal bold 12px/12px Muli !important;
  margin-left: 12px !important;
  width: 100% !important;
}

.btnDocuments2 {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  /*background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 28px;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 6px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  font: normal normal bold 12px/12px Muli !important;
  margin-left: 12px !important;
  width: 100% !important;
}



.btnQuick {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  /*background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 28px;
  border-radius: 5px !important;
  padding-left: 6px !important;
  padding-right: 0px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  width: 100% !important;
}

.btnGeneralSign:disabled,
button[disabled] {
  opacity: 0.4;
}

.ZoneUploadContent {
  padding-bottom: 0px !important;
}

.contentZoneUpload {
  border-style: dashed !important;
  border: 2px dashed #9D9D9C;
  border-color: #9D9D9C;
  border-width: 3px !important;
  border-radius: 10px !important;
}

.contentZoneUploadList {
  border-style: solid !important;
  margin-top: 10px;
  border-color: #DADADA !important;
  border-width: 2px !important;
  background-color: #FFF;
  height: '100%';
}

.contentZoneUploadList:hover {
  border-style: solid !important;
  border-color: #B2B2B2 !important;
  border-width: 2px !important;
  box-shadow: 0px 2px 3px #00000040;
  background-color: #FFF;
  ;
}

.buttonPlusZoneUpload {
  margin-left: 110% !important;
  margin-top: -37px !important;
}

#paperElectro4 {
  width: 442px;
  min-height: 227px;
}

.titlePopUp {
  text-align: center;
  margin-bottom: 41px !important;
  margin-top: 58px !important;
  font: normal normal bold 18px/21px Muli !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.titlePopUpCertificate {
  text-align: center;
  margin-bottom: 4px !important;
  margin-top: -5px !important;
  font: normal normal bold 18px/21px Muli !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.rangeText {
  text-align: center;
  font: normal normal normal 14px/22px Muli;
  color: #4F4F4F;
  padding-top: 78px;
  opacity: 1;
}

#rangeText2 {
  padding-top: 50px
}

.certificateText {
  padding-left: 4%;
  padding-top: 4px;
  color: #9D9D9C;
}

.selectQR {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 82% !important;
  margin-top: 2px !important;
  opacity: 1;
}

.selectQR div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.selectCustom {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 80% !important;
  padding: 0px !important;
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
}
.selectCustomP {
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 80% !important;
  
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
}

.selectCustomP .MuiButtonBase-root{
 padding:  5px !important;
 
}


.selectCustom div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.selectCustom .MuiInput-underline {
  margin: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  color: #706F6F !important;
}

.selectCustom label {
  display: inline;
}

.MuiInput-underline:before {
  border: 0px !important;
  text-align: left;
  text-justify: left;
}

.MuiInput-underline:after {
  border: 0px !important;
  margin-top: -100px !important;
}

.MuiSelect-icon {
  color: #F5AE19 !important;
}

.selectCustom label {
  margin: 0px !important;
  margin-top: -20px !important;
  padding: 0px !important;
  height: 10px !important;
}

.selectCustom option {
  width: 237px;
  max-width: 237px !important;
}

.selectCustom option:hover {
  background-color: #706F6F;
  color: #FFF;
}

.menu-signs-type li:hover,
.menu-signs-type li:hover button {
  background-color: #878787;
  color: #FFF;
}

.passwordGet {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 80% !important;
  padding: 0px !important;
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
  filter: opacity(16%) !important;
}

.opionCustom {
  width: 237px;
  white-space: normal !important;
  text-transform: unset !important;
}

.opionCustom:hover {
  background-color: #706F6F !important;
  color: #FFF !important;
}

/*.Mui-selected {
  border: 0px !important;
  background: #706F6F !important;
  color: #FFF !important;
}*/

#paperToken {
  min-height: 215px;
}

.titlePopUpForwardMail {
  text-align: center;
  margin: 14px 0px 9px !important;
  font: normal normal bold 18px/21px Muli !important;
  line-height: 30px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #e55200 !important;
  opacity: 1;
}
.sign-paper-source-signature {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  min-height: 321px;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center !important;
  justify-content: center !important;
  overflow-y: auto;
  outline: none;
}
#paperElectro1 {
    width: 442px;
    min-height: 452px;
}
#paperElectro2 {
   width: 442px;
  min-height: 296px;
}
@media (max-width: 600px) {
 #paperElectro1 {
  width: 90% !important;
  min-height: 482px;
}

#paperElectro2 {
  width: 90%;
  min-height: 296px;
}
}



.paperRecipient {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 187px;
  max-height: 548px;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px #00000080;
  border: 0.2860439717769623px solid #E3AC31;
  border-radius: 8px !important;
  opacity: 1;
  overflow-y: auto;
  outline: none;
}

.mainContentPaper {
  box-sizing: border-box !important;
  padding: 20px !important;
  padding-bottom: 0px !important;
  margin-Top: -25px;
  margin-bottom: 25px;
  /*max-width: 1024px !important;*/
  min-width: 424px !important;
  min-height: 380px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.contentPaperPlan {
  padding: 15px;
  margin-right: 20px;
  margin-left: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
  border-radius: 10px !important;
}

.contentPaperDocuments {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
  border-radius: 10px !important;
  width: 98%;
}

.contentPaperDocuments2 {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  /* margin-top: 40px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
  border-radius: 10px !important;
  width: 98%;
}


.mainContentPaperDocuments {
  box-sizing: border-box !important;
  border: 1px solid #E3AC31 !important;
  padding: 0px !important;
  margin-Top: -25px;
  margin-left: 2%;
  margin-right: 2%;
  /*min-width: 1024px !important;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.contentDocumentSigned {
  padding: 7px !important;
  padding-top: 6px !important;
}

/* Aquí */

.contentDocumentSignedHome2 {
  padding: 0px !important;
  margin: 0;
  /* padding-top: 7px !important; */
}

.contentDocumentSignedHome {
  padding: 20px !important;
  padding-top: 7px !important;
}

.containerTabSignedDocumentHome {
  /* height: 100%; */
  width: 100%;
  /* overflow: auto; */
  /* box-sizing: border-box; */
}

/* Fin */

.searchBar {
  background-color: #EDEDED;
  height: 72px;
  width: 100%;
  padding: 15px;
  ;
  box-sizing: border-box;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.validateSubtitle {
  font: normal normal bold 12px/15px Muli;
  text-shadow: 0px 0px 0px #EEEEEE !important;
  color: #4F4F4F !important;
  font-size: 14px !important;
  letter-spacing: 0.2px;
}

.styleUser {
  -webkit-backdrop-filter: brightness(40%);
          backdrop-filter: brightness(40%);
}

.styleUser .MuiMenu-paper {
  width: 100%;
  max-width: 380px;
  max-height: 100%;
  height: 100%;
  background: #FFFFFF center 98% no-repeat padding-box;
  background-image: url(/static/media/logoGse.6a1c2f40.PNG);
}

.tableResponsive {
  height: 80%;
  padding-bottom: 20%;
  width: 100%;
  overflow: scroll;
  position: fixed;
  background-color: white
}

.recipientSearch {
  width: 454px !important;
  box-shadow: 0px 3px 0px #00000033 !important;
  height: 36px;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #706F6F !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
}

.fieldSearch {
  width: 68%;
  height: 39px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAIAAABLixI0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAJZSURBVEhLrZRJiyJBEIXd9w13BPXgQUURBC/+/4v+AEFa3HAB9eC+jzrfdORo6ZQwTfc7VEVFRL6MeBlZxtvtZvghmNT7J6BfF871er3b7c7nM58ul8vr9dpsNom+gw7Xcrns9/s8CQGj0YjTbrfHYrFUKmUyvW3liQu70+n0ej1sh8PxSfWHC1wul9Pp5PP5CoWC2+2W/Bc8bQJLt9u1Wq0QsZL1VAHd8XjkCQWNNxoN/GrBMx517ff7Wq1msVioAqJ4PB6NRlGKlfTLNmiHZIhIp9lsVlZp8eD6+PgYDAZOpxPSTCaTTqchlRCQig6HA5uxU7VaJVPF/kL1SHixWNAdRjAYTCaTWiLAObIBhnQ9m83Er4XiQhFYzGbz9XqFC0P8Wng8HiSjZaKbzUZ5NVBcUFCI9KtLBOgOSI6u/IqLJKHDxhDnC359ghwSdPdTXCiFllL/ZDKRcX8BgtIaCZQWCASUVwPFxWGHQiG2hZQJYGJfqpvP5+12G+Hxk8wdUAENHjOB/PV6nU/oOPtwOMx8cXXwrFYrbhUGLIQSiUQul5NVWjzdofF43Gw2RWOOlZDoSO/iJAcbyZjVSCQiq+544gKj0ajVatEs1+g+YhgoiBOxKA0bTz6ff6F75QJ0gV7T6VROjQSeiM0Aoxq1Q0d1oFgsaul0uAT0iEzQoTe3kkHFSb9UPRwOOXRC5JTLZQ5Nlrzlegfy0RQp2AAu2EulEgdFSM3E/4N+EZ6/yHa7pU2/3w+pCn21rjv4bfBHqVQq6vs7XJwmazkH9f0drn/xZb3ewmD4DZIUnY6Te4xhAAAAAElFTkSuQmCC);
  background-color: #FFF;
  background-position-y: 50% !important;
  background-repeat: no-repeat, no-repeat;
  background-position: 1%, 98%;
  border: 1px solid #C6C6C6 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  padding-left: 32px !important;
  padding-right: 25px !important;
}

.fieldSearchResponsive {
  width: 100%;
  height: 39px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAIAAABLixI0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAJZSURBVEhLrZRJiyJBEIXd9w13BPXgQUURBC/+/4v+AEFa3HAB9eC+jzrfdORo6ZQwTfc7VEVFRL6MeBlZxtvtZvghmNT7J6BfF871er3b7c7nM58ul8vr9dpsNom+gw7Xcrns9/s8CQGj0YjTbrfHYrFUKmUyvW3liQu70+n0ej1sh8PxSfWHC1wul9Pp5PP5CoWC2+2W/Bc8bQJLt9u1Wq0QsZL1VAHd8XjkCQWNNxoN/GrBMx517ff7Wq1msVioAqJ4PB6NRlGKlfTLNmiHZIhIp9lsVlZp8eD6+PgYDAZOpxPSTCaTTqchlRCQig6HA5uxU7VaJVPF/kL1SHixWNAdRjAYTCaTWiLAObIBhnQ9m83Er4XiQhFYzGbz9XqFC0P8Wng8HiSjZaKbzUZ5NVBcUFCI9KtLBOgOSI6u/IqLJKHDxhDnC359ghwSdPdTXCiFllL/ZDKRcX8BgtIaCZQWCASUVwPFxWGHQiG2hZQJYGJfqpvP5+12G+Hxk8wdUAENHjOB/PV6nU/oOPtwOMx8cXXwrFYrbhUGLIQSiUQul5NVWjzdofF43Gw2RWOOlZDoSO/iJAcbyZjVSCQiq+544gKj0ajVatEs1+g+YhgoiBOxKA0bTz6ff6F75QJ0gV7T6VROjQSeiM0Aoxq1Q0d1oFgsaul0uAT0iEzQoTe3kkHFSb9UPRwOOXRC5JTLZQ5Nlrzlegfy0RQp2AAu2EulEgdFSM3E/4N+EZ6/yHa7pU2/3w+pCn21rjv4bfBHqVQq6vs7XJwmazkH9f0drn/xZb3ewmD4DZIUnY6Te4xhAAAAAElFTkSuQmCC);
  background-color: #FFF;
  background-position-y: 50% !important;
  background-repeat: no-repeat, no-repeat;
  background-position: 1%, 98%;
  border: 1px solid #C6C6C6 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  padding-left: 32px !important;
  margin-right: 8px !important;
}

.checkButtonDocumentSigned input[type=checkbox] {
  border: 1px solid #9D9D9C !important;
}



.headerTableValidate {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  font: normal normal bold 11px/16px Muli;
  color: #E55200;
  padding: 10px !important;
}

.headerTableValidate tr th,
.headerTableValidate tr {
  padding-bottom: 11px !important;
  padding-top: 11px !important;
}

.headerTableDocumentSigned {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal bold 11px/16px Muli;
  letter-spacing: 0px;
  color: #E55200;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 3px !important;
}

.headerTableDocumentCircuit {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal bold 11px/16px Muli;
  letter-spacing: 0px;
  color: #E55200;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 0px !important;
}

#tableUsers tr th:first-child {
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  border-spacing: 0px !important;
  border: 0px !important;
}

#tableUsers tr th:last-child {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-spacing: 0px !important;
  border: 0px !important;
}

#tableUsers tr th,
.headerTableDocumentSigned tr {
  padding: 7px !important;
}

.headerTableDocumentSigned tr th,
.headerTableDocumentSigned tr {
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 6px !important;
}

.headerTableDocumentSigned tr {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
}

.headerTableDocumentSigned tr th:first-child {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.headerTableDocumentSigned tr th:last-child {
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.modal-signer {
  width: 489px !important;
}

.tableSignedDocumentsCF tr {
  width: 100% !important;
  box-sizing: border-box !important;
}

.borderActive {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
}

.tableSignedDocumentsCF tbody tr:hover {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
}


.tableSignedDocumentsCF .Mui-selected {
  background: #ffffff !important;
  color: #000 !important;
}

.tableSignedDocuments {
  width: 99% !important;

}

.tableSignedDocuments tr {
  width: 100% !important;
  box-sizing: border-box !important;
  border: 1.4px #ffffff solid !important;
}
 #rowFolder {
  width: 100% !important;
  box-sizing: border-box !important;
  border: 1.4px #ffffff solid !important;
  border-bottom: hidden !important;
}


#rowFolder:hover {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
  border-bottom: hidden !important;
}
.tableSignedDocuments tbody tr:hover {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
}
.tableSignedDocuments tbody tr:hover:first-child {
  border-top: 1.4px #ffffff solid !important;
}


.tableSignedDocuments .Mui-selected {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
}

.containerTabSignedDocument {
  height: 300px;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  /*Estilos estÃ¡ndar experimentales (Principalmente Firefox)*/
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: 10px !important;
}

.containerTabSignedDocument::-webkit-scrollbar {
  -webkit-appearance: none;
}

.containerTabSignedDocument::-webkit-scrollbar:vertical {
  width: 10px !important;
}

.containerTabSignedDocument::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none !important;
}

.containerTabSignedDocument::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

.containerTabSignedDocument::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

.containerTabSignedDocument::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

.selectOrderBy {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: .4em 1.4em .3em .8em;
  width: 130px;
  box-sizing: border-box;
  border: 0px solid #aaa;
  border-radius: .3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23878787%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #fff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

/***EXPLORER */

.buttonGoogleDriveExplorerBlack {
  text-transform: capitalize !important;
  background-color: transparent !important;
  padding-top: 8px !important;
  padding-bottom: 11px !important;
}

.buttonGoogleDriveExplorer {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAhdEVYdENyZWF0aW9uIFRpbWUAMjAyMTowNToxMSAxMToyNDo0NXNtQm8AAAOpSURBVEhLvVZLiBVHFD1Vr/v1fGTGBP//DEPGEIkySoiiCC5URBcScBNCJB/CbCIhyxDIwhiQEBRcuBGNIQTBD7jSLKLgwi+Cn0CiRCbioBMmTkwy8/p1v9eVc6tq9ImvZx7q8wz9eupWU+eee2/dKmUI1KBy5xhQ/htQ2lueFrKshpryJgodC52JeEhYPvc+0v7vaQmh3JT9fRa4latQQSeit/YhmLPREZZOrED24DIQTiaNsr49DyjxmYsZUwHiIbSsvwhVGTprSj+tgo6m+C+aAQOTpVDtXdBm+BrTFfqJZoFCmCrE90hoi0MMzVLnIKtn5NDU6iwvCI6wQXFS0JILkwzDlO+7J/3H2huFRsYKaoDRLlodhZ7UjWjlYUTLDyJa8QPCN3Zw8/7XMKk2pszXBISyWHIf0bLdUC3TWG1zEczfgmDe2ygu/AR61nqSjviPxwcVpv7ffJgsge58HcErW1Fc/BWS8x/5GYeod5ctOmOq3pIPEiZ85Su0oar8i7D3GzvWkxfxWYz09312LNBtsxH2bAOYz/EgTYUhncAr5q0wcwOC6Wu8AQi6P0Ry9XMql/w7FBd9ARVNtUWVB5HFKqWCHIFGKpgOFZm7WiRXSFYaRPrLdm8h2DzCJV9zko1/nAJiSEVhDiNDFHT3QbfP9wZG9/YRZH+esWrSG3uQjQ74GbbiBe9Av9xLlVKI9UGF9UNqw1XsZJE8UiH5FHUIJ7EdBgx3guTyp37WIVp+gPayy30d5BLK3oqW7IQqRN5Awde+5KnyG+dK3PwP6EGFig8jub4d6a3vWEj7kQ1fhe541aaiHlR8aZup3DrAM6vNmxyki0SrjyOYudaOs5HbiE9vQNj1AXt/zWK+im1qJDOFNlTpRFYacFEYg3xH51V8oc9U+n8kYaufcbDHSfEltG26Yceln9cifO0zOrDOjvNgyn9h9HgXF2/l1qy5NXhCbov6ZSxHlhnpR3pzL7IhHpz0diIyQXz2Pf5yx+VcUcZv3lSY/vot4nPvcmvssSbZKvUeQXXwFKp3TzLvj0erFio+/7Gp/HHoiZCOwVYrvVWFFhvmfNDrasnux7rqxkIqH3DkjHUgobT3HFlM7ia5D53JI/MQFs7mxbMGbMxKFRp48skEPE1JyDzZPDYZVh3DqgsdPdxDbEUS42ZCGgH3qL2XjhydYROuaHARbiDMjYJC7F88iNZVRyWHQPvmAeipK2HsISqm56RWyDTX4ztauguFuZvxP6t0nMDdtK9jAAAAAElFTkSuQmCC);
  background-Position: center;
  border-radius: 5px !important;
  width: 28px !important;
  margin-left: 20px !important;
  height: 28px !important;
}

.buttonGoogleDriveExplorerCF {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAhdEVYdENyZWF0aW9uIFRpbWUAMjAyMTowNToxMSAxMToyNDo0NXNtQm8AAAOpSURBVEhLvVZLiBVHFD1Vr/v1fGTGBP//DEPGEIkySoiiCC5URBcScBNCJB/CbCIhyxDIwhiQEBRcuBGNIQTBD7jSLKLgwi+Cn0CiRCbioBMmTkwy8/p1v9eVc6tq9ImvZx7q8wz9eupWU+eee2/dKmUI1KBy5xhQ/htQ2lueFrKshpryJgodC52JeEhYPvc+0v7vaQmh3JT9fRa4latQQSeit/YhmLPREZZOrED24DIQTiaNsr49DyjxmYsZUwHiIbSsvwhVGTprSj+tgo6m+C+aAQOTpVDtXdBm+BrTFfqJZoFCmCrE90hoi0MMzVLnIKtn5NDU6iwvCI6wQXFS0JILkwzDlO+7J/3H2huFRsYKaoDRLlodhZ7UjWjlYUTLDyJa8QPCN3Zw8/7XMKk2pszXBISyWHIf0bLdUC3TWG1zEczfgmDe2ygu/AR61nqSjviPxwcVpv7ffJgsge58HcErW1Fc/BWS8x/5GYeod5ctOmOq3pIPEiZ85Su0oar8i7D3GzvWkxfxWYz09312LNBtsxH2bAOYz/EgTYUhncAr5q0wcwOC6Wu8AQi6P0Ry9XMql/w7FBd9ARVNtUWVB5HFKqWCHIFGKpgOFZm7WiRXSFYaRPrLdm8h2DzCJV9zko1/nAJiSEVhDiNDFHT3QbfP9wZG9/YRZH+esWrSG3uQjQ74GbbiBe9Av9xLlVKI9UGF9UNqw1XsZJE8UiH5FHUIJ7EdBgx3guTyp37WIVp+gPayy30d5BLK3oqW7IQqRN5Awde+5KnyG+dK3PwP6EGFig8jub4d6a3vWEj7kQ1fhe541aaiHlR8aZup3DrAM6vNmxyki0SrjyOYudaOs5HbiE9vQNj1AXt/zWK+im1qJDOFNlTpRFYacFEYg3xH51V8oc9U+n8kYaufcbDHSfEltG26Yceln9cifO0zOrDOjvNgyn9h9HgXF2/l1qy5NXhCbov6ZSxHlhnpR3pzL7IhHpz0diIyQXz2Pf5yx+VcUcZv3lSY/vot4nPvcmvssSbZKvUeQXXwFKp3TzLvj0erFio+/7Gp/HHoiZCOwVYrvVWFFhvmfNDrasnux7rqxkIqH3DkjHUgobT3HFlM7ia5D53JI/MQFs7mxbMGbMxKFRp48skEPE1JyDzZPDYZVh3DqgsdPdxDbEUS42ZCGgH3qL2XjhydYROuaHARbiDMjYJC7F88iNZVRyWHQPvmAeipK2HsISqm56RWyDTX4ztauguFuZvxP6t0nMDdtK9jAAAAAElFTkSuQmCC);
  background-Position: center;
}

.buttonOneDrive {
  margin: 2px;
  background-Repeat: no-repeat;
  background-Position: center;
  width: 32px !important;
  height: 32px !important;
  border: 0px solid;
}

.buttonDropbox {
  margin: 2px;
  background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAAABHNCSVQICAgIfAhkiAAAAjJJREFUOE91krFrE3EUx7/fIxDut1iXTC1kEJzEBgK9+2Wwq9AhDi6Kth3chCaDxa1xE0ENCB1cTJeCTvofdMnvYii0kzim4qqkDtdUkjx55a6c6eUHx/3uvXff3/t+fo/IrMPDQ3N+fv4SwM1CofBgZWXlVzbvnKsA2CP5JggCfUuaZ7pxzq2JyC7JpSQ2AHDXWvtdv6Moeigi7wGYJO88z3sSBME3/Wa/318aj8e7ANaypyf7PyLyGIAluT2bF5ExgNelUmmHzrl3AJ7miGjoK8ktADdERC0v5tT9FZH1C2tRFG2oLQB+UviT5PMgCPZTDgm/bZLPUnsi8oPkfWttP8voFoBPIvKxWCy+qlarcV6XvV5vcTKZaHfXST6y1v6+YJQWR1FUFpEPJDthGO7NsYqjo6OF0Wi0NZ1Oy8aYZqVSGV4KOefqichCInAgIs1arXacFUwQ7AAoa1xEjj3PuxeG4YDdbrdFUpN5q+37/os4jssk3wJYzbm5IclNFVoG0CF5e45YE0AdwJ28vIh8McZsZGE3ALQAXEt+OAWgtzkwxgzOzs40p6OQrhOSG2EYHvwHOxkDBd5RBiJSJ3lxAQB0yjc9z1sWkbY+xph2CvqKkHOu4ft+Rwtm2YnIMAF7oDj0EGvt57S9S2sa6Ha7mlAWA5LKLm+pFYXetNa2c4V0RuI4bpBUXimrWbETAI1sN1eszQynwl3PqJzmscntaPboKIpWRUQF1WpLB2+OXfwDptIEf/D2j4UAAAAASUVORK5CYII=);
  background-Repeat: no-repeat;
  background-Position: center;
  width: 32px !important;
  height: 32px !important;
}

.buttonDropbox:hover {
  background-Image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAAABHNCSVQICAgIfAhkiAAAAiBJREFUOE91kjFsE0EQRefvyQVpCH0iuUCiQpiWhrSWsueloCGCOAUdkp0CRJfQRUiAJaQUNHEaJGh8vnXjzhUSVKkQpYNoQQ4FsixuBk20Zx32eavdndmZ+W8/qLCstWvGmCMiumGMedDr9X4W43Ec3wZwysyvvfenRCR5HPnGWrttjDkmos1wN2bmuvf+m57jON4honcA1kL8ExE97vf7X/WMRqOxKSLHALaL3XUvIr9F5BGAOwCelcT/Ang1m80OEMfxWwBPFpPC+TMRtUTkOgCVvFGSNxOR3Utp1tpmkHUlJP4Qkedpmr7POSi/MNXTXJ6IfGfm+4PB4MucUaPRuCkiH4nog4i89N7/KZuyXq9vVCqVIwDXptPpw+Fw+OuSUZ7snKuKyImIdNM01R8pXc65dWZuAagC2E+SZDIvZK11xpgTIloPr0ch6WzBHorggIiq4f4MwL0kScYK+xCABpeWiHSMMS/0oYi8IaKtkrQJM+/BOVdj5i6AW2XFmHkfgANwd0WzvjGmWTRk2xhzSERXw4MLZm5GUTQmIjWnTt4qFDsH0EySZPQfbD0o8DCdgnRZllUDuzGAvSzLasaYTpDcyUEvFbLWtqMo6mpCCbtJADtSHNrEe5/kE86l6UUcx0lgoXJqKxygUraUnfe+U1ooeKQNoF1gtVjvnJnbxWmWpBXNGeDuFqpclLEpnWixtXNOJehPjvVH1XirHP8PnCcEfFrt8oYAAAAASUVORK5CYII=);
}

/*********************/

.onedrive-paper {
  display: block;
  left: 50%;
  min-width: 100%;
  min-height: 40%;
  max-height: 100%;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
}

.breadcrumbOneDriveExplorer {
  height: 30px;
  color: #000;
  border: 0px;
  width: 100%;
  border-bottom: 1px #706F6F solid;
}

.barcontrolOnedriveExplorer {
  margin-bottom: 20px;
  ;
}

.buttonOneDriveExplorer {
  background: #f4f4f4 !important;
  border-radius: 2px !important;
  margin-left: 5px !important;
  font-size: 12px;
  text-transform: capitalize !important;
}

.buttonOneDriveExplorerActive {
  border-radius: 2px !important;
  background: #106ebe;
}

.buttonOneDriveExplorer:hover {
  background: #106ebe !important;
  color: #FFF !important;
}

.titleModalOneDriverExplorer {
  font-size: 18px;
  color: #000;
}

.tableFoldersOneDrive {
  color: #000;
  ;
}

.tableFoldersOneDrive thead {
  color: #f36a00 !important;
  background: #FFEFD8;
}

.tableFoldersOneDrive tbody tr:hover {
  background: #f4f4f4;
}

.newFolderModel-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  min-height: 180px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 14px;
  opacity: 1;
  text-align: center !important;
  justify-content: center !important;
  overflow-y: auto;
  outline: none;
}

.active-sheet {
  box-shadow: 2px 0px 15px 1px rgba(0, 0, 0, 0.77) !important;
}

.firmante {
  width: 44px;
  height: 30px;
  background-color: violet;
}

.firmante button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAXCAYAAACS5bYWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhJJREFUeNrMV9FtwjAQdRC/VbMBbAD9r9T0v1KyAekEpBukE5ROUDMBqTpAU6kDpFIHgA3IBPQOvUOnCFBCHIqlk2McX57P994Zb7PZmHO1KIpm1E0xnJMlWZat667v/RNQbhMG28RH70xAxwroI9k9nsdN/PThaNYWEB1ncGQ6laOn9yx9MzkJLNkVmd9hVIfUhQKaxjH1LxjbJr68rgmGKDK4FVmOXJUox5eWswJooIC+NgUqadBVRCPuyEbqZ45uTEDzU3z2HTM+UHatpktoqm3zDS8MQ78uK3VEAC4FOe/2vL7CHIN+orWtFYcje0v2UXdz6jlRLBdwBUiUE7iCNiTVKYYqZE1TQAeFwX4rkW7SGGxGxoCKatlExZJUGMGm9DuTK6Heh48A65MDSpLCT+lcugAiV8RaQU9jKEIJ4mWVvN6lCnxYdXJfvL7fMdBtIQCxuCAUmPtUG1ki59dKRazayDOtT53q7AGgBhE0Kh2MunVxPg4xXpIPBrkA0B+yGwHquijMAKYEkO3xSS6jzJo91WugNjVR0RwzSasXmYe6akCLvSMFQD4UqYuRjqpE8F0B1ZIp0YyrILV0/fJOHETVwM9SHbcGa5EmGkiBU/Aha7bTiwyO9w1kEU2cIoKRSwK7yNlYRddXY2scNxdgd2xWksPEyi4RrOTrAnpZNv1vdU6wFiw26INDbG7b/gQYAN/k067NNN0QAAAAAElFTkSuQmCC);
}

.btnFirmante {
  content: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAXCAYAAACS5bYWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhJJREFUeNrMV9FtwjAQdRC/VbMBbAD9r9T0v1KyAekEpBukE5ROUDMBqTpAU6kDpFIHgA3IBPQOvUOnCFBCHIqlk2McX57P994Zb7PZmHO1KIpm1E0xnJMlWZat667v/RNQbhMG28RH70xAxwroI9k9nsdN/PThaNYWEB1ncGQ6laOn9yx9MzkJLNkVmd9hVIfUhQKaxjH1LxjbJr68rgmGKDK4FVmOXJUox5eWswJooIC+NgUqadBVRCPuyEbqZ45uTEDzU3z2HTM+UHatpktoqm3zDS8MQ78uK3VEAC4FOe/2vL7CHIN+orWtFYcje0v2UXdz6jlRLBdwBUiUE7iCNiTVKYYqZE1TQAeFwX4rkW7SGGxGxoCKatlExZJUGMGm9DuTK6Heh48A65MDSpLCT+lcugAiV8RaQU9jKEIJ4mWVvN6lCnxYdXJfvL7fMdBtIQCxuCAUmPtUG1ki59dKRazayDOtT53q7AGgBhE0Kh2MunVxPg4xXpIPBrkA0B+yGwHquijMAKYEkO3xSS6jzJo91WugNjVR0RwzSasXmYe6akCLvSMFQD4UqYuRjqpE8F0B1ZIp0YyrILV0/fJOHETVwM9SHbcGa5EmGkiBU/Aha7bTiwyO9w1kEU2cIoKRSwK7yNlYRddXY2scNxdgd2xWksPEyi4RrOTrAnpZNv1vdU6wFiw26INDbG7b/gQYAN/k067NNN0QAAAAAElFTkSuQmCC);
  min-width: 5px;
  height: 23px;
}

.btnFirmante:hover {
  content: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAXCAYAAACS5bYWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAiFJREFUeNrMl0FOwkAUhqcNO0Po2oWwcC/uTcSNKxOJHoB6AsoN8ATWEzgcwIDxAFZPUBOXLtqFbrXxAPU98g95EiAtbZFJJtNhOjPfvPf+N8VK01RtqnxeWj41fXRHVL3d+/Q763z7n0C59Bg2zxr2hkDbAvSK6gme23nWqWEhvygQubOzYnhoXE/vadrTWws2/FL1vR3lFIZdbtUWNecGmvoutTfo6zx7WFULDFZkuJhqgFg1Vna3LWYNUFOA3uYFnYZBhRbtUsP1QPzM1nUJNFhnzVrJiu+I2hDDCXKqLrIHZwMnqyqlRQDHKuf5xwtejzHG0MOioFPY9x91tF9Xj1kFKZ49oXIDF0JEAcGFdCBzO7nICpO8ISCNYn1cqHUt68DdDBTOX5sLbiwlxOVhvoc1eL63JJMM4Z2k9NQFiEAIK0Y+dZEREghvMhfXAwL2xRpaeO6Z59sVgyrEK1cOg1cAPqGNAaLgIZNFIgF6zbcj1ciuGFTBgqbIsRHCr4V+RGuwNcc4CB/skA9axaXgAyYByNR9JpZxzaoFt1dTHKonrNlmkf7JBi+n1lnWbECTrRUXgNmoKz6MpFWNBR8EqBS2saY7DzmDJdA3PkkJVlVYJxLulrAaYSJBQnjBQVrTlX7IwL13EIvJiX1YsFumgMuIWVdY1xF9rUouZcDO1AzABoQ12UZYE69jfCMkef9bbRJWQ8UKbWeZmouWXwEGAHLv3Iq5MGmlAAAAAElFTkSuQmCC);
}

.box {
  float: right;
  margin-right: -4px !important;
  position: relative;
  overflow: hidden;
}

.box button {
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAXCAYAAACS5bYWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhJJREFUeNrMV9FtwjAQdRC/VbMBbAD9r9T0v1KyAekEpBukE5ROUDMBqTpAU6kDpFIHgA3IBPQOvUOnCFBCHIqlk2McX57P994Zb7PZmHO1KIpm1E0xnJMlWZat667v/RNQbhMG28RH70xAxwroI9k9nsdN/PThaNYWEB1ncGQ6laOn9yx9MzkJLNkVmd9hVIfUhQKaxjH1LxjbJr68rgmGKDK4FVmOXJUox5eWswJooIC+NgUqadBVRCPuyEbqZ45uTEDzU3z2HTM+UHatpktoqm3zDS8MQ78uK3VEAC4FOe/2vL7CHIN+orWtFYcje0v2UXdz6jlRLBdwBUiUE7iCNiTVKYYqZE1TQAeFwX4rkW7SGGxGxoCKatlExZJUGMGm9DuTK6Heh48A65MDSpLCT+lcugAiV8RaQU9jKEIJ4mWVvN6lCnxYdXJfvL7fMdBtIQCxuCAUmPtUG1ki59dKRazayDOtT53q7AGgBhE0Kh2MunVxPg4xXpIPBrkA0B+yGwHquijMAKYEkO3xSS6jzJo91WugNjVR0RwzSasXmYe6akCLvSMFQD4UqYuRjqpE8F0B1ZIp0YyrILV0/fJOHETVwM9SHbcGa5EmGkiBU/Aha7bTiwyO9w1kEU2cIoKRSwK7yNlYRddXY2scNxdgd2xWksPEyi4RrOTrAnpZNv1vdU6wFiw26INDbG7b/gQYAN/k067NNN0QAAAAAElFTkSuQmCC) no-repeat center center;
}

.box button:hover {
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAXCAYAAACS5bYWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAiFJREFUeNrMl0FOwkAUhqcNO0Po2oWwcC/uTcSNKxOJHoB6AsoN8ATWEzgcwIDxAFZPUBOXLtqFbrXxAPU98g95EiAtbZFJJtNhOjPfvPf+N8VK01RtqnxeWj41fXRHVL3d+/Q763z7n0C59Bg2zxr2hkDbAvSK6gme23nWqWEhvygQubOzYnhoXE/vadrTWws2/FL1vR3lFIZdbtUWNecGmvoutTfo6zx7WFULDFZkuJhqgFg1Vna3LWYNUFOA3uYFnYZBhRbtUsP1QPzM1nUJNFhnzVrJiu+I2hDDCXKqLrIHZwMnqyqlRQDHKuf5xwtejzHG0MOioFPY9x91tF9Xj1kFKZ49oXIDF0JEAcGFdCBzO7nICpO8ISCNYn1cqHUt68DdDBTOX5sLbiwlxOVhvoc1eL63JJMM4Z2k9NQFiEAIK0Y+dZEREghvMhfXAwL2xRpaeO6Z59sVgyrEK1cOg1cAPqGNAaLgIZNFIgF6zbcj1ciuGFTBgqbIsRHCr4V+RGuwNcc4CB/skA9axaXgAyYByNR9JpZxzaoFt1dTHKonrNlmkf7JBi+n1lnWbECTrRUXgNmoKz6MpFWNBR8EqBS2saY7DzmDJdA3PkkJVlVYJxLulrAaYSJBQnjBQVrTlX7IwL13EIvJiX1YsFumgMuIWVdY1xF9rUouZcDO1AzABoQ12UZYE69jfCMkef9bbRJWQ8UKbWeZmouWXwEGAHLv3Iq5MGmlAAAAAElFTkSuQmCC) no-repeat center center;
}

.inputText .MuiOutlinedInput-root {
  height: 33px;
  margin-bottom: 11px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #00000033;
  color: #9D9D9C !important;
  border-radius: 10px;
  opacity: 1;
}

.btnGeneralTSA {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  text-transform: initial !important;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
  width: 100%;
  height: 33px;
}

.checkbox-label {
  margin: 5px 5px 0 0;
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  margin: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
}

.checkbox-custom {
  position: absolute;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #707070;
}

.seleccionada {
  position: relative;
  border-radius: 5px;
  opacity: 1;
  width: 220px;
  height: 123px;
}

.checkbox-label input:checked~.checkbox-custom {
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: rotate(0deg) scale(1);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 2px #00000029;
}

.checkbox-label input:checked~.seleccionada {
  background-color: lawngreen;
  border-radius: 5px;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  border: 3px solid #98C500;
  transform: rotate(0deg) scale(0);
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
}

.checkbox-label input:checked~.checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  right: 6px;
  top: 2.5px;
  width: 6px;
  height: 11px;
  border: solid #98C500;
  border-width: 0 3px 3px 0;
  background-color: transparent;
}

.drag-sign {
  display: block;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.load-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.drag-sign img {
  box-shadow: 0px 5px 10px #68666680;
}

.drag-sign p {
  text-align: center;
  font: normal normal normal 12px/24px Muli;
  letter-spacing: 0px;
  color: #E55200;
}

.sign-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 75px;
}

.sign-container img {
  height: calc(80%);
}

.firma-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 47px;
}

.firma-container img {
  height: calc(100%);
  width: 100px
}

.show-loading {
  position: fixed;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url(/static/media/loader.3c02dc5b.gif);
  background-position: center center;
  background-repeat: no-repeat;
}

.container-sign {
  position: absolute;
  background: #EDEDED 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 1px solid #878787;
  /*height: 50px;
  width: 50px;
  top: 30px;
  left: 30px;*/
}

.resizer {
  width: 12px;
  height: 12px;
  /*border-radius: 50%;
  background: white;
  border: 1px solid #878787;*/
  position: absolute;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABJQTFRFAAAAgICAgICAgICAgICAgICAtO8NbwAAAAZ0Uk5TAGD/MO+gAsrfdgAAACJJREFUeJxjYAABRjDJIAThKEI4AigcZgjHBMJxhXACsHIAWY8Df2W6/88AAAAASUVORK5CYII=);
  border: none;
}

.resizer.nw {
  top: -2.5px;
  left: -2.5px;
  cursor: nw-resize;
}

.resizer.ne {
  top: -2.5px;
  right: -2.5px;
  cursor: ne-resize;
}

.resizer.sw {
  bottom: -2.5px;
  left: -2.5px;
  cursor: sw-resize;
}

.resizer.se {
  bottom: 2px;
  right: 2px;
  cursor: se-resize;
}

.container-sign canvas {
  height: calc(100%);
  width: calc(100%);
  /*border-radius: 12px;
  border: 2px solid #878787;*/
}

.container-sign button {
  position: absolute;
  border-radius: 50%;
  padding: 0.5em;
  width: 21px;
  height: 21px;
  /*border: 2px solid #F5AE19;
  background-color: #FFFFFF;
  color: #F5AE19;*/
  cursor: pointer;
  outline: none;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAXCAYAAADk3wSdAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFaADAAQAAAABAAAAFwAAAABRE2NUAAADc0lEQVQ4Eb2UTWwTRxTH/zNeG5sQEmNVdisCh0RtUEhEDkRKoqhR1KqVWglo6CWq4FJ64UTg0EMPgQMSnLghLjSXShABElRuKVGQHOpEfMVEBAkKpZBULgR/xPZ6vV7vzjCzyMZfkZJLnzQ7M2/e/PY/M2+GYBXLXPMPEQM9xO0fADc7ifuDPDcSBuHWHNcTMxlNvRw4oC7Xm07qObPBzp+VwBdDpLF1G93cDtrUDuIOgGWegqUfg6081K1oMMEL2RMNX82fq8co+dKXfO3qtU9i5uKEytdg+ciPqWxw5x98Ao4SpLwhgbnQnigvpNeAex9SiE7y7PXeuXJWqS0VrhdYRJsvL6pqsPN8CSYbcg/XuuQiqLrOhUdeq5f9n9ng3BX/kH7/6GJ1kOxb8XucG8mKISs5z7keq/DZHVbguRsDCxJKLYYe2tjWYv+h7MNis9BvDYvyLVBYsUdY/A706W+QC30tfOmyaNEkChh1fZi5FOigMg9l2lQbbe4C9e4CSz2ywSKFoIdHAEuD0jIMODdXT4HD270RhO+kMrFlHtaY0gB33y+gzZ2wUgvI3/7BBjo/PgznjmM14dLh8Ha5iatxN+Wej4TaQN0gCLCz9XsQUDHOhbomKG0CvoqRTa2gLp+fMiPrkDelnlnR35GfGxVDHMTlE/uYsveUa//WCwdLRlDIxZ9SnfiW5dWrNms5hPwdoYpbUFoPwfP5tL0VXP1bgPfVHpQAGIkFqM6OOGUF46aZfFTNhEPspVyOBLq6jgMuL9z9F2yw0rK37kFJcV791lUsXf+uLTX5ZW3eSY+xUutf5RpbiTn+5sZwsqTuyc2Tmhb5qRawDk86uJvf/fPXsxIqjxWxDZ+ezsSewfxvSnbXbdq9UUQ37odBtlQ+gzPh6VBscj/P/zOxDn2ca3eP8OfTp3g4HB4rqrGVyg6hzoN/NYymlp9NQZ05JE7dLMbUrWX6ZH7rwaIawCtlINTf3z9WDKx4+WdnZweFzPFN+fntW60pKCQPpWkHlC0doCIT2Mo8jPhD+/XP0a144TkIizaEPB7P3u7u7ncPhCBXQOWfIpFIs6Zp44SQPW5zEW5zCR5rCU5qQmeNyCkt0JXtKDh8KRE+1tfXd0bOK7caaHFQqmaMDQr4oPDtEqVJlJdiJQ9E/YBSOt7b2/tCtP8fewswmxHT7m8CcQAAAABJRU5ErkJggg==) 0% 0% no-repeat padding-box;
  border: none;
  top: -10px;
  right: -10px;
}

[data-title]:hover:after {
  border-radius: 3px;
  opacity: 1;
  /*transition: all 0.1s ease 0.5s;*/
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #706f6f;
  color: #FFF;
  font: normal normal normal 11px Muli;
  /*font-size: 50%;*/
  position: absolute;
  padding: 3px 6px 3px 6px;
  top: -2.5em;
  /*bottom: -1.6em;*/
  /*left: 100%;*/
  white-space: nowrap;
  /*box-shadow: 1px 1px 3px #222222;*/
  opacity: 0;
  /*border: 1px solid #111111;*/
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.menu-tabs {
  position: relative;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF99 100%) 0% 0% no-repeat padding-box;
  background-color: #FFFFFF;
  opacity: 1;
  height: 80vh;
  width: 20%;
}

.tab-panel {
  width: 80%;
}

.Mui-selected {
  /*background: rgb(237, 237, 237);
  background: transparent linear-gradient(180deg, #DADADA00 1%, #DADADA 100%) 0% 0% no-repeat padding-box;
  border-bottom: 3px solid #E55200 !important;
  color: #E55200 !important;*/
  color: #E55200 !important;
}

.verticalTabsItems {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #6D6E71;
  background-color: #fff;
  opacity: 1;
}

.verticalTabsItems:hover {
  color: #E55200 !important;
  font-weight: bold !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.over-list {
  border-radius: 10px;
  border: 1px solid #B2B2B2;
  background-color: #FFFF;
}

.dialog-container {
  width: 450px;
  max-height: 300px;
  margin: 20px;
  display: 'flex';
  flex-direction: 'row';
  overflow-y: scroll;
}

.tableDocumentSigned {
  width: 100%;
}

.space-between {
  display: none !important;
}

.btn-close-popup {
  position: absolute;
  padding: 0px !important;
  float: right;
  top: 2px;
  right: 2px;
  width: 20px !important;
  height: 20px !important;
  display: none !important;
}

.btn-close-popup svg {
  width: 20px !important;
  height: 20px !important;
}

.paper-sign {
  position: relative !important;
  background: #F3F3F3 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 4px #00000040 !important;
  border-radius: 5px !important;
  width: 220px !important;
  height: 123px !important;
  margin: auto !important;
}

.grid-sign {
  padding-left: 12px;
  padding-bottom: 12px;
}

.icon-button {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.icon-button span {
  display: none;
  font-size: 10px;
  text-align: center;
  height: auto;
  width: 38px;
  color: #4F4F4F;
}

.icon-limpiar span {
  margin-top: 25px;
}

.icon-anadir span {
  width: 200px;
  margin-top: 30px;
}

.icon-anadir {
  margin-left: 10px !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAF1QTFRFAAAATk5OT09PT09PT09PUFBQTk5OTU1N////////////////ZWVl9PT0////////nJycyMjI////w8PDp6en////////////////////x8fHdXV1+fn5////////NHmavgAAAB90Uk5TAMD/v/BAgFAwv/+v///vEP//YMD/gEDPIHD/kPiw8NV+ACQAAACOSURBVHiczZDLEsIgDEUxvvCqpSi1WqX//5mFzmAmNN258O6YM+GexJh/yYbqbAtaENoVtD/UOf7Uyp6As9UML1ekNE4xbOGJPG6K4R2BKKCrex59+mxGQC/IswEjCPTKPSVeGIY88X0IwxaDnGLD92e1y9jIKC7u1K3sNVsOfA0ZN+ae0amXT33RaoQzAT7nCHZ6oJLRAAAAAElFTkSuQmCC);
}

.icon-anadir:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAF1QTFRFAAAA864Y9a4Z864X86wX86sY86sY8qwT////////////////9rg1//rw////////+dF+/Oa3////+uKy+teM/////////////////////Oa29L1J/vz3////////EVhNkAAAAB90Uk5TAMD/v/BAgFAwv/+v///vEP//YMD/gEDPIHD/kPjwsFPbDrEAAACHSURBVHiczZDbDoIwEERhES0DUipV7vz/Z0o1tdlSQkJ84Lw1k+2c3Sg6CzH5JDZaJXSxUXr1uf3VSmRALkKGxR0LpQwYVlBECo+AYQ1NpPH0e17N8tknAhqWtCVcBBZ1pseimKE2E78HM6zQ8ylnOIybXd++3u3FkZOZmGTwhjMwi51DH+IN94IHYqDLMu8AAAAASUVORK5CYII=);
}

.icon-limpiar {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFFQTFRFAAAATU1NT09PT09PTk5OTU1NTk5OT09PTk5OTk5OTk5OUFBQTk5OT09PUFBQTk5OTk5OT09PUFBQT09PTk5OT09PTU1NTk5OUFBQTk5OTU1N3Qq5agAAABt0Uk5TAFDv/49g/5/9/pAgoPpA/NDPEPPDv9fnMPFw8JoF/wAAAI5JREFUeJyt0M0SwiAMBODYtaj9oVpFq+//oEKHCUub8eQeuHzJwCLyhxwaHG1p4RxOtuDsLpYlAWBYFsNUdkaysUqSdfRqcGKHXneGdrQoSTxHlo6ELMpQiZoh2VT6pshqKuIBz80nV0avuM1EJDKz8cW1bYRsJ6vdHyE8gVfIWdTeqQ/ojz9l0ddZ5Ge+DVYIWgn92YAAAAAASUVORK5CYII=);
}

.icon-limpiar:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFdQTFRFAAAA9a8W9K4Z9a4Z9K0Z9K0Y9a4Y9a0Y9a0Y9a0X9a0X9K4Z968Y9a4Y9KwX9a0Z968Y9K0Y9a4Z9a4Z/68Q9q0Y9a0W9K4Y9K0Y9a0X9K8V9K4Y9q0Zd0CDKQAAAB10Uk5TAFDv/49g//+f/f6QIKD6/0D80M8Q88O/1+cw8XCzo85sAAAAlklEQVR4nK3Q2xLCIAxF0dhjEy+tolbR6v9/p5RyCS3jk5sZXtYAQ4j+0KbBti4tC/OuLryXQ80mAbhis9QsytqyLE2Lt2MSsFs5YXRR0LcnZedIk7g9m0i4cBZlTvpCklUkWJKuyeItCRnA6J9fkhBdcRsUKaFBm364tIUoW4m3+8PaJ/CyoTHZG8wCNeFPPmjKRvrZF2hrCYb/gsmOAAAAAElFTkSuQmCC);
}

.button-sign {
  width: 40px;
  height: 40px;
  background: #EDEDED 0% 0% no-repeat padding-box !important;
  background-position: center !important;
  box-shadow: 0px 2px 0px #00000033 !important;
  border: 0.6078676581382751px solid #4F4F4F !important;
  border-radius: 5px !important;
  margin-right: 16px !important;
}

.button-sign span {
  display: none;
}

.button-sign-sel {
  width: 40px;
  height: 40px;
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-position: center !important;
  box-shadow: 0px 2px 0px #00000033 !important;
  border: 0.6078676581382751px solid #4F4F4F !important;
  border-radius: 5px !important;
  margin-right: 16px !important;
  border: none !important;
}

.button-sign-sel span {
  display: none;
}

.button-cargar {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADxQTFRFAAAATk5OT09PUFBQTk5OTU1NT09PUFBQTk5OT09PTk5OTU1NTk5OT09PTk5OUFBQTU1NTk5OTk5OUFBQvn0NtgAAABR0Uk5TAID/MH9gv0DA8G8/oODQIFCwkBCzSq3iAAAAi0lEQVR4nO3SwQ6DIBBF0TcDiBRFq///r0qiDcYRXFjdeBeIyYmZgMDbXyPm26DSawakdcU8b634hbUavy3toTsLOYW5GXmbvR5aEuYT4Md7fwrGHoBNW4Qh3k+AcgXouj4ugCnAHvjGBRiycJgf43LMdQaG9HVsj2Dymy0FGdIOGhlC0TZ14N7uaQIvBQRGYmpU5gAAAABJRU5ErkJggg==) !important;
}

.button-cargar-sel {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADxQTFRFAAAA////////////////////////////////////////////////////////////////////////////2EloEwAAABR0Uk5TAID/MH9gv0DA8G8/oODQIFCwkBCzSq3iAAAAi0lEQVR4nO3SwQ6DIBBF0TcDiBRFq///r0qiDcYRXFjdeBeIyYmZgMDbXyPm26DSawakdcU8b634hbUavy3toTsLOYW5GXmbvR5aEuYT4Md7fwrGHoBNW4Qh3k+AcgXouj4ugCnAHvjGBRiycJgf43LMdQaG9HVsj2Dymy0FGdIOGhlC0TZ14N7uaQIvBQRGYmpU5gAAAABJRU5ErkJggg==) !important;
  border: none !important;
}

.button-dibujar {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEtQTFRFAAAAUFBQT09PT09PUFBQTk5OTk5OT09PTU1NTk5OTk5OT09PT09PT09PUFBQTU1NTk5OT09PTk5OTk5OT09PTk5OUFBQT09PTU1NRwP+3gAAABl0Uk5TABCv/yDA0M9ggJDw7+AwUG/foF+fsEC/cC+Wy5EAAADUSURBVHic7ZLNDoMgEITB4W8BsVq1ff8n7e6hSWswcGnSg3MDPmZgQKlLv5EeMJge0MLBd3ABpCI6QJe0zmObK4jaY2qDEZQROpJHILX9zG0GlkY35b4yhdbxrDBjnLZ0WNgP48XSJJGM+481/cCRfM8ni2z0ilm27RlLlfNA4SaHDMvvowipWpRwo1QOhAnBPODKyYVIkoL4WOk96ipHiEHKsfIw0sFJUSY7tYE96Mmj6KhuxwVhN/U7HpQsh1dL+9YOKhzeVoHPPYbyETu+16U/1AvhsQaKnp2rWwAAAABJRU5ErkJggg==) !important;
}

.button-dibujar-sel {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEtQTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////g5kLBwAAABl0Uk5TABCv/yDA0M9ggJDw7+AwUG/foF+fsEC/cC+Wy5EAAADUSURBVHic7ZLNDoMgEITB4W8BsVq1ff8n7e6hSWswcGnSg3MDPmZgQKlLv5EeMJge0MLBd3ABpCI6QJe0zmObK4jaY2qDEZQROpJHILX9zG0GlkY35b4yhdbxrDBjnLZ0WNgP48XSJJGM+481/cCRfM8ni2z0ilm27RlLlfNA4SaHDMvvowipWpRwo1QOhAnBPODKyYVIkoL4WOk96ipHiEHKsfIw0sFJUSY7tYE96Mmj6KhuxwVhN/U7HpQsh1dL+9YOKhzeVoHPPYbyETu+16U/1AvhsQaKnp2rWwAAAABJRU5ErkJggg==) !important;
  border: none !important;
}

.button-digital-sel span {
  margin-top: 10px;
  margin-left: -11px;
  margin-bottom: 2px;
}

.button-digital {
  background-image: url(/static/media/ico-predefinido.da9986ac.svg) !important;
}

.button-digital-sel {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-image: url(/static/media/ico-predefinido-sel.f525613b.svg) !important;
  border: none !important;
}

.button-sign-container {
  margin-top: 40px !important;
  width: 170px !important;
}

.gridButtons {
  direction: row;
  justify-content: flex-end;
}

.label-sign {
  position: absolute;
  font: normal normal bold 15px/10px Muli;
  letter-spacing: 0px;
  color: #FFFFFF;
  width: 70%;
  text-align: center;
  text-shadow: 0px 0px 6px #000000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 1;
}

.title-background {
  padding-left: 2%;
  margin: 0;
  color: white;
  height: 100px;
  padding-bottom: 90px !important;
  padding-top: 20px;
}

.title-background-home {
  margin: 0;
  height: 100px;
  padding-top: 20px;
  width: 100%;
  
}

.title-background-home h1 {
  
  margin: 0;
  color: white;
}

.home-upload-grid {
  margin-top: -50px !important;
  margin-bottom: 30px;
}

.zone-upload-container {
  max-width: 1350px;
  margin-top: -35px !important;
  padding-left: 2%;
  padding-right: 2%;
}

#paper-upload-result {
  padding-top: 35px !important;
  padding: 25px;
}

.paper-upload {
  padding: 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
}

.paper2-upload {
  padding: 15px 35px 35px 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
}
.addSigner {
  padding: 15px 35px 35px 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
}
@media (max-width: 600px) {
  .addSigner {
    padding: 10px 15px 20px 15px !important; /* Reduce el padding en móviles */
    max-width: 98%; /* Reduce el ancho máximo para ajustarse mejor en pantallas pequeñas */
  }
}
.validate-style-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  padding: 20px;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  min-height: 340px;
  background-color: #EDEDED;
  border: 2px dashed #9D9D9C;
  cursor: pointer;
  box-sizing: border-box;
}


.icon-upload {
  width: 54px;
}

.base-validate-container {
  flex: 1 1;
  height: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px !important;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  background-color: #EDEDED;
  border: 2px dashed;
  cursor: pointer;
  box-sizing: border-box;
}

#containerRecipient {
  margin-bottom: 30px !important;
  min-height: 120px;
}

.base-style-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px !important;
  padding: 20px;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  min-height: 340px;
  background-color: #EDEDED;
  border: 2px dashed;
  cursor: pointer;
  box-sizing: border-box;
}

#base-container {
  padding-top: 124px !important;
}

.box-botonera {
  display: inline-block;
  /* margin-top: 20px; */
}

.input-certificate {
  margin-top: 4px !important;
  cursor: pointer;
  height: 18px;
  width: 18px;
  background-color: white !important;
  margin: auto;
  border: 1px solid #707070;
  -webkit-appearance: none;
  border-radius: 3px !important;
  position: relative;
}

#encryptContainer {
  min-height: 249px;
}

#decryptContainer {
  padding-top: 17px !important;
  padding-bottom: 40px !important;
  min-height: 100px;
}

#decryptContainerOption {
  padding-top: 14px !important;
  padding-bottom: 19px !important;
  min-height: 2px;
}

#headerTableCertificate th {
  padding: 10px 3px !important;
  max-height: 40px !important;
}

.checkmark-title {
  margin-top: 8px !important;
  font: normal normal normal 15px/10px Muli;
  color: #4F4F4F !important;
  font-size: 14px !important;
  padding-left: 8px;
  position: absolute !important;
}

.input-certificate:checked {
  background-color: #E55200 !important;
  border: 1px solid transparent;
}

.input-certificate:checked:after {
  content: '\2714';
  font-size: 13px;
  position: absolute;
  color: white;
  top: -1.9px;
  left: 3px;
}

.isLoad {
  margin-top: 15px
}

.box-list-signs {
  display: inline-block;
  height: 300px !important;
  overflow-x: hidden;
}

.titlesMenu {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: -15px;
  font-size: 15px;
}

.wordActive {
  color: #0294C2;
  text-align: center;
  max-width: 90px;
}

@media screen and (max-width: 1200px) {
  .wordActive {
    margin-left: -10px;
  }
}

@media screen and (max-width: 630px) {
  .titlesMenu {
    width: 65%;
  }
  .gridCades{
    align-items: stretch !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 600px) {
  .btnFirmaCades {
    width: 89vw;
  }
  .btnGeneralNewDos{
    width: 89vw;
  }
}

@media screen and (max-width: 584px) {
  .titlesMenu {
    width: 70%;
  }
}

@media screen and (max-width: 534px) {
  .titlesMenu {
    width: 80%;
    min-width: 327px;
  }
}

@supports(object-fit: cover) {
  .box button {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media screen and (max-height: 610px) {
  .styleUser .MuiMenu-paper {
    background: #FFFFFF center 0% no-repeat padding-box;
    background-image: url(/static/media/logoGse.6a1c2f40.PNG);
    background-position-y: 550px;
  }
}

@media screen and (max-width: 765px) {
  .bodyMiddle {
    background: none;
  }

  .bodyFull {
    background-image: none;
  }

  .bodyMiddle::after {
    background-color: initial;
  }

  #titleValidate {
    margin-top: -37px !important;
    visibility: hidden;
  }

  .title-background {
    padding-left: 2%;
    height: 80px;
    padding-bottom: 0px !important;
    font: normal normal bold 20px/20px Muli !important;
    color: #E55200;
    margin: 0 25px 0 20px
  }
}

@media screen and (max-width: 505px) {
  .btnGeneralSign {
    font-size: 16px !important;
    height: 47px !important;
    letter-spacing: 1px;
    border-radius: 9px !important;
    width: 219px;
  }

  .btnValidate {
    width: 455px !important;
  }

  .btnFirmaCades {
    width: 87vw;
  }

  .btnGeneralNewDos{
    width: 87vw;
  }



  .gridButtons {
    justify-content: center
  }

  .btnGeneralSignIcon {
    font-size: 16px !important;
    padding-left: 22px !important;
    height: 47px !important;
    letter-spacing: 1px;
    padding-right: 0px !important;
    border-radius: 9px !important;
    width: 219px;
  }
}

@media screen and (max-width: 600px) {
  .menuChange .MuiMenu-paper {
    border: 1.25px solid #9D9D9C;
    width: 455px;
  }

  .paperTableValidate {
    width: 360px;
    padding: 15px;
  }

  .validate-style-container {
    padding-bottom: 32px !important;
    height: 148px !important;
    padding-top: 32px !important;
  }

  .btnChange {
    width: 455px !important
  }

  .gridValidate {
    align-items: flex-start !important;
    flex-direction: column-reverse !important;
  }

  .contentPaperPlan {
    margin-top: 10px;
  }

  .btnDocuments {
    height: 30px;
    padding-right: 10px !important;
    padding-left: 10px !important;
    font: normal normal bold 12px/12px Muli !important;
  }

  
  .btnDocuments2 {
    height: 30px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    font: normal normal bold 12px/12px Muli !important;
  }

  .btnQuick {
    height: 30px;
  }
}

@media screen and (max-width: 1279px) {
  .btnContinue {
    display: none !important;
  }

  .btnContinue2 {
    display: flex;
    opacity: 1;
    cursor: pointer;
    background-color: #E55200 !important;
    justify-content: center;
    align-items: center;
    background: #E55200 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033 !important;
    -webkit-box-shadow: 0px 3px 0px #00000033 !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    color: #FFFFFF !important;
    text-shadow: 0px 2px 0px #00000040 !important;
    border: none !important;
    padding: 8px 24px !important;
    margin-bottom: 20px;
    text-transform: initial !important;
    margin-top: 14px !important;
    font-size: 17px !important;
  }

  .btnContinue2:hover {
    background-color: #db4f04 !important;
  }
}

@media screen and (max-width: 1250px) {

  .tableDocumentSigned {
    border-collapse: separate;
    border-spacing: 10px 0;
  }



  .tableDocumentSigned td {
    padding: 15px 0;
  }

  .tableDocumentSigned tr {
    border-bottom: 1px solid #D6D6D6;
    border-top: 1px solid #D6D6D6;
  }

  .tableDocumentSigned tr>* {
    width: 80vw;
    display: inline;
    border-bottom: none;
    text-transform: capitalize;
    padding: 10px 0 0 0;
    text-align: left;
  }

  .tableDocumentSigned tr td:first-child {
    display: inline-block;
    font-weight: bold;
    padding-bottom: 2px;
    width: 100% !important;
  }

  .modal-signer {
    top: 25% !important;
    left: 3% !important;
    width: 94% !important;
  }

  .dialog-container {
    width: 100%;
    position: relative;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 330px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 991px) {
  .rangeText {
    display: none !important;
  }

  .menu-tabs {
    display: none;
  }

  .firma-container img {
    height: calc(80%);
    width: calc(42%)
  }

  .buttonPlusZoneUpload {
    margin-left: 37% !important;
    margin-top: -17px !important;
  }

  #apply {
    display: initial !important;
    margin-top: 22px !important;
    margin-bottom: 20px !important;
  }

  .btnPopup {
    display: initial !important;
    margin-top: 11px !important;
  }

  .title-modal {
    padding-left: 17px;
  }

  .isLoad {
    margin-bottom: 10px
  }

  .btnPut {
    width: 154px !important;
    height: 41px !important
  }

  .contentPaperDocuments {
    margin-left: 3%;
    width: 94%
  }

  .tableSignedDocuments tbody tr:hover {
    border: none !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: none !important;
  }

  .tableSignedDocuments .Mui-selected {
    border: none !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: none !important;
  }

  .tab-panel {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .over-list {
    border: 0.5px solid #E3AC31 !important;
    border-radius: 5px;
  }

  .space-between {
    display: block !important;
  }

  .btn-close-popup {
    display: inline !important;
    background-image: url(/static/media/icono-cerrar.84dcb6e2.svg);
    background-position: 0 0 !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
    margin: 5px !important;
    width: 24px;
    height: 26px;
  }

  .paper-sign,
  .seleccionada {
    width: 190px !important;
    height: 70px !important;
    padding: 0 !important;
  }

  .paper-sign img {
    width: calc(20%);
    height: auto;
  }

  .MuiBox-root,
  .MuiBox-root-111 {
    padding-top: 7px !important;
  }

  .grid-sign {
    padding-top: 10px !important;
  }

  .checkbox-label,
  .checkbox-input,
  .checkbox-custom {
    position: absolute !important;
    top: 0;
    width: 15px;
    height: 15px;
  }

  .checkbox-label input:checked~.checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    right: 4px;
    top: 2.4px;
    width: 4px;
    height: 7px;
    border: solid #98C500;
    border-width: 0 2px 2px 0;
    background-color: transparent;
  }

  #icon-delete {
    position: absolute !important;
    bottom: 0;
    width: 13px !important;
    height: 17px !important;
  }

  #icon-delete img {
    width: 13px !important;
    height: 17px !important;
  }

  .sign-container {
    width: calc(100%) !important;
  }

  .sign-container img {
    width: calc(41%) !important;
    height: calc(65%) !important;
  }

  .box-list-signs {
    margin-top: 15px;
    max-height: 180px !important;
  }

  .load-sign {
    width: 100%;
    margin-top: -10px !important;
    margin-bottom: -5px !important;
  }

  .btn-panel {
    display: inline-block;
    margin: 0 !important;
  }

  .btn-panel button {
    width: 90px;
    max-width: 106px;
    height: 61px;
    padding: 0;
    margin: 0;
    margin: 5px;
  }

  .btnGeneral {
    border-radius: 7px !important;
    height: 40px !important;
  }

  .btnGeneralResize {
    border-radius: 7px !important;
    margin-bottom: 38px !important;
    height: 40px !important;
    margin-top: 40px !important;
    width: 182px !important;
    height: 45px !important;
  }

  .button-sign-container {
    margin-top: 17px !important;
    width: 103.7% !important;
  }

  .button-sign {
    padding: 5px !important;
    height: 61px;
    width: 90%;
    background-position: top !important;
    box-shadow: 0px 2px 0px #00000033 !important;
  }

  .button-sign span {
    display: table-cell;
    color: #4F4F4F;
    vertical-align: bottom;
    text-align: center !important;
    font: Muli;
    font-size: 11px;
    font-weight: 600;
    height: 40px;
    width: 70px;
  }

  .icon-button:hover:after {
    content: attr(data-title);
    visibility: hidden
  }

  .button-sign-sel:hover:after {
    content: attr(data-title);
    visibility: hidden
  }

  .button-sign:hover:after {
    content: attr(data-title);
    visibility: hidden
  }

  .button-sign-sel {
    padding: 5px !important;
    height: 61px;
    width: 90%;
    background-position: top !important;
    box-shadow: 0px 2px 0px #00000033 !important;
  }

  .button-sign-sel span {
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    font: Muli;
    font-size: 11px;
    font-weight: 600;
    height: 40px;
    width: 70px;
    color: #FFFFFF;
  }

  .button-digital-sel {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAJKADAAQAAAABAAAAFQAAAACVh1DwAAACsklEQVRIDdWUwZXTQBBE8XsEICJAGSAi8BABzsAmAjsCUAYmAzsDiwNniwhWcOImEYGVgfmlndbOaEdrFk7Ue+Werq7uGY1lL14kcL1eM+Q3sIA9/L5YLBpiBHyqL734BU8XGYIEr3zOS5r1Db9mz0MHgXuYQou4UjdRvjOc4oCQhTuQb+EFpqC9Iv/YS6GAd76rJx5h6Vl7XeEAzSfdPB1r4WRDWR8kBJBfDPGD5LX1DBFBT2ybHJVHBhI0B3VQQxV6EDWjEaUTS2jQOppJLs3m/Qxnhc3RJpHpfpMiGFJO65bjyaFhZ/o0YliZifhxrJO0vpCP4swC38Z7L8R1yoa+854uVQ81fEfv/TroJHpqoQ6NT63xlkPH/UdL2MPCeljXUChNm4t47AGv8ryE9t32qSYaVuj6CwhxIXkLd1B1Rd1KTfwAk6B+puCSRS/qQIbMFpNYkk8PJMs7/kc2WugpCTqUg9r0F0zhVUp8pDGwh3onHh0KLYcuYMtacNNBaNVQuV47H9up549ymo9+QPlUAx4dzuCmXgo6+BS6ueeBCdpItyRsUt3oGbT/Kvk+T31oOxUS+IQW3T75FupbMeTDPDJtJOrpeiic4RouPdXcQqGB5juxXnruiSHkq0KB9dkzPIgsx+jhEAqoW1Ks4Ry0QQbl62ZMPXoJhxshbqC0OTQUxttb2MkQN6w7fjk6kGMt5rCHHaypNcQRvsd5oSM2eCqfRwFvgbCCW5j5on6Njp7O53HQBnB8urj6bxlzV7CFhuhmZqfjLmAF9U7oqf4a9GdwDe+goWexe/ZQmhzU16dhOtx7mN8ahGcJt/AEL9Cgg9y8/fEdmtuIITqEvntxCYV6+Hz4yFgWD+m40jtSw2ru3RqdfnHzQNMGDujQck/C8IIqb5R41ET9QDqf/7/hNxW/N/UhcKy+AAAAAElFTkSuQmCC) !important;
    background-position: 50% 20% !important;
  }

  .button-digital {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAVCAYAAAAuJkyQAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAJKADAAQAAAABAAAAFQAAAACVh1DwAAADuUlEQVRIDdWWQU4bQRBFbRPWOCdg2GUT4ayyZLKNZOycIJMTMJzAJhdgcgLsE2QMElJWGW5glAPQPkHMGoHzfrvLGjeGsMgmJbWruup39e/qmhk3Gxuk3++37+/v95vNZofwvNVqXZdlOY2h4DoPDw8H8oOZgHExxubdbveAfKnmi8ViurW1dQV+bnHTTTOkRYQNBizM6/5gO2LHFxcXpXAk/Y4/jXAj1h7XNzo8PDzCNwTXjrAiVnCQkzp+RUinBXDGog7jFrtEO4YkJamvBPaIIYzwV+iK0SCeoXYZ5WQy+YRu9Ho95ctkSwJeWMsl96+7u7vu5eXlTJNX+gkn1mJtMoZ1TtK5YiZg0kAykw97cn5+3q/FCypYMd+Tj8oMURlD2BNyFvWcikMsJ/x2e3v7B/qNsL5CITiINxGgLqGKFb4dbQKhYT1uNriE+I3m4ZoLi9U1fdWHqK5euAHt8LWlCUw/S6sy0k8Jdz1locew5oiD+HUxngeiH3wzNtlIRnH1I8THAfteuqVTo3Uidb2T8zkhyUjVAdOG1Ig+uYHYacjjl+L3hMCNnsulGJhKmmJ8lFYPtWUg86Va/1VZ2WB/3dv4je+dqqXNGTmJc8hV2F/wR/DllPhPrHRjMDh9UwfbiK3h2UDNFxMS5gM9lMmAdCZSjBRi2nQmfyzEXoOJ3WtzH4W5qrMAvFd/JwipBkVpeCHpGUYC9gPYauld/nJ1Jf4eMxHaZTierL1l9GW/vqnZpASul2IeL1NfaeOwuSOexBib69EOtshIEqr3KOcytPnXXxmJhpBSLwxI4NS4MZxKtcH4R1QxyPdRlWwTnq4OuWzqNfNTKreD/sah5hbEt/YGD7fjmtoogPRSLLF3GBX2iOEUA9xh5JgJvmvsBFu4EmIFWhgdSBgvwmE4fLpCkyoYHXTbnGDH1o9NOcMjOxdIG5DkwMB1zcIJJ83wiViJtqupw/TZ0TeqUEXU8LIB6ACPBOw18dSq5wkJpYV8gX2/QDCFWIo7YcxZ5Iipj6bMV6I1kE+Dw4Gb6mW3AtQMHVrXDP4IdzuEZlpPXmfQFSE5wgaJnc5A/0KTW5+JU3IlyhdXRj7JGiE5wkmGmDckGMdVEealQi61QI8q5KzphHW3+IZUstiU5xEhA4VrG5JMH9JKg2vTHzVnmE2aSuiPmB6ClLiGXc9ab+HfKE8SMjTEEn0s2UDDmr2yeNDa1CpQD83CYcqneqsOlv1XQvECVQ6CCeSSEBMZ2dMw14eywtYD4sz33+o/dRr8KktC+6wAAAAASUVORK5CYII=) !important;
    background-position: 50% 20% !important;
  }

  .button-digital span {
    margin-top: 10px;
    margin-left: -11px;
    margin-bottom: 2px;
  }

  .button-cargar-sel,
  .button-dibujar-sel {
    background-position: top !important;
  }

  .icon-limpiar:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFFQTFRFAAAATU1NT09PT09PTk5OTU1NTk5OT09PTk5OTk5OTk5OUFBQTk5OT09PUFBQTk5OTk5OT09PUFBQT09PTk5OT09PTU1NTk5OUFBQTk5OTU1N3Qq5agAAABt0Uk5TAFDv/49g/5/9/pAgoPpA/NDPEPPDv9fnMPFw8JoF/wAAAI5JREFUeJyt0M0SwiAMBODYtaj9oVpFq+//oEKHCUub8eQeuHzJwCLyhxwaHG1p4RxOtuDsLpYlAWBYFsNUdkaysUqSdfRqcGKHXneGdrQoSTxHlo6ELMpQiZoh2VT6pshqKuIBz80nV0avuM1EJDKz8cW1bYRsJ6vdHyE8gVfIWdTeqQ/ojz9l0ddZ5Ge+DVYIWgn92YAAAAAASUVORK5CYII=);
  }

  .icon-anadir:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAF1QTFRFAAAATk5OT09PT09PT09PUFBQTk5OTU1N////////////////ZWVl9PT0////////nJycyMjI////w8PDp6en////////////////////x8fHdXV1+fn5////////NHmavgAAAB90Uk5TAMD/v/BAgFAwv/+v///vEP//YMD/gEDPIHD/kPiw8NV+ACQAAACOSURBVHiczZDLEsIgDEUxvvCqpSi1WqX//5mFzmAmNN258O6YM+GexJh/yYbqbAtaENoVtD/UOf7Uyp6As9UML1ekNE4xbOGJPG6K4R2BKKCrex59+mxGQC/IswEjCPTKPSVeGIY88X0IwxaDnGLD92e1y9jIKC7u1K3sNVsOfA0ZN+ae0amXT33RaoQzAT7nCHZ6oJLRAAAAAElFTkSuQmCC);
  }

  .label-sign {
    font: normal normal bold 11px Muli;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 6px #000000;
    top: 43%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 1;
  }

  .paper-upload {
    width: 100%;
    padding: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .icon-upload,
  .textZoneUpload,
  .textZoneUploadO {
    display: none !important;
  }

  .textZoneUploadLocal {
    width: 90%;
    font-size: 17px;
    margin: 27px 0 15px 0;
  }

  .base-validate-container {
    padding-top: 0px !important;
    height: 168px !important;
    border-radius: 10px !important;
  }

  .base-style-container {
    min-height: 148px;
    border-radius: 10px;
  }

  .btnGeneral {
    font-size: 16px !important;
    border-radius: 9px !important;
    width: 182px !important;
    height: 45px !important;
  }

  .menu-signs-type li {
    font: normal normal normal 14px Muli;
    height: 30px;
    min-height: 30px;
    color: #4F4F4F;
  }

  .title-background-home {
    margin: 0;
    background: none;
    height: 40px;
  }

  .box-botonera {
    margin: 5px 0 10px 0 !important;
    grid-row-gap: 11px;
    row-gap: 11px;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    justify-content: center !important;
    align-content: center !important;
  }

  .input-certificate {
    margin-top: -11px !important;
    position: absolute;
  }

  .icon-button {
    width: 42px;
    height: 42px;
  }

  .icon-limpiar,
  .icon-anadir {
    background-size: 35px;
    margin-left: 20px !important;
  }

  .icon-button span {
    width: 50px;
    display: block;
  }

  .checkmark-title {
    margin-top: -7px !important;
    margin-left: 20px;
    position: absolute !important;
  }

  #box-icons-buttons {
    visibility: hidden;
  }

  .drag-sign img {
    height: 50% !important;
    width: auto !important;
  }
}

@media screen and (max-width: 390px) {

  .paper-sign,
  .seleccionada {
    width: 130px !important;
    height: 70px !important;
    padding: 0 !important;
  }
}
.sign-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 440px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
  ;
}

.sign-paper-man {
  position: absolute;
  display: 'flex';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 440px;
  background: #FFFFFF;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  opacity: 1;
  padding: 0 0;
}

.sign-paper-man-center {
  position: relative;
  padding: 7px 50px;
  top: 19px !important;
  background: transparent !important;
}

.tabs-manuscrita {
  width: 110%;
  padding-top: 0px !important;
}
.message {
  padding-left: 20px !important;
  padding-right: 20px;
  padding-top: 72px;
  width: 100% !important;
  text-align: center;
  font: normal normal normal 18px/22px Muli;
  letter-spacing : 0px;
  color: #9D9D9C;
  opacity: 1
}
.signs {
  position: relative;
  font-weight: bold;
  justify-items: right !important;
  font-size: 22px;
  top: -2px !important;
  background: transparent !important;
  text-align: center !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.tabs-manuscrita .Mui-selected {
  background: transparent !important;
  text-align: center;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.sign-button-option-sel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 0px #00000033;
  border: 0.6078676581382751px solid #F5AE19;
  border-radius: 7px;
  opacity: 1;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 0px;
  color: #F5AE19;
  opacity: 1;
  font-size: 18px;
}

.title-sign-draw {
  text-align: left;
  letter-spacing: 0px;
  color: #E55200;
  opacity: 1;
  font-size: 19px;
}

.sig-pad {
  width: 480px;
  height: 250px;
  background: transparent;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.sign-buttons {
  width: 100%;
  height: 30px;
}

.sign-buttons-default {
  font-size: 9pt;
  color: #ff9800;
  height: 30px;
}

.sig-image {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.sig-image {
  width: 100%;
  height: 100%;
}

.btnResponsive {
  display: none !important;
}

.draw-sign {
  position: absolute;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: normal normal normal 18px/22px Muli;
  color: #9D9D9C;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .sign-paper-man {
    max-width: 500px;
    min-height: 240px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
  .message {
    padding-left: 7px !important;
    padding-right: 10px !important;margin-top: -10px !important;
    width: 2% !important;
    text-align: center;
    font: normal normal normal 18px/22px Muli;
    letter-spacing : 0px;
    color: #9D9D9C;
    opacity: 1
  }
  .signs {
    top: 0px !important;
  }
  #box-icons-buttons{
    margin-top:-12px;
    margin-right: 7px !important;
  }
  .mostrar {
  visibility: visible;
  margin-top: 20px !important;
  }
  .tabs-manuscrita {
    width: 109%;
    padding-top: 0px !important;
  }
  .sign-paper-man-center {
    padding: 11px 20px 0 20px;
    top: 1px !important;
  }
  .sig-pad {
    width: calc(100%);
  }
  .load-sign {
    width: calc(100%);
  }
  .drag-sign {
    width: calc(100%);
    margin-bottom: -5px !important;
  }
  .drag-sign img {
    width: calc(90%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000000;
    box-shadow: none;
  }
  .textZoneUploadSign {
    display: none !important;
  }
  .predefined-alert {
    display: none !important;
  }
  .btnResponsive {
    display: flex !important;
  }
  .sig-pad {
    width: calc(100%);
  }
  #signature-canvas {
    width: calc(100%);
    height: auto;
  }
  #selected {
    top: 6px;
    width: 100%;
  }
}

body {
  font-family: "adobe-clean", sans-serif;
}

.dragme {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 270px;
  height: 203px;
  cursor: move;
}

.drag-container {
  position: relative;
  text-align: center;
  overflow: auto;
  border: 1px solid;
  border-radius: 5px;
  background-color: #ededed;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

#draggable {
  background-color: #ccc;
  border: 1px solid #000;
  box-shadow: 0 2px 4px 0 rgba(156, 148, 148, 0.5);
}

.pdf-container-workarea {
  padding-top: 0px !important;
}

.pdf-botonera-firmas {
  padding-top: 0px;
  width: 90%;
  max-height: 60px;
}
.gridBotonera {
  grid-gap: 7px;
  flex-direction: row;
}
.pdf-botonera-btn {
  background: #ededed 0% 0% no-repeat padding-box;
  padding-top: 7px;
  border: 1px solid #989898;
  font: normal normal 100 14px/14px Muli;
  color: #4f4f4f !important;
  border-radius: 10px;
  opacity: 1;
  min-height: 42px;
  margin: 0px 20px 5px 0px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: auto;
}

.pdf-botonera-btn-active {
  background: #f5e9d0 0% 0% no-repeat padding-box;
  padding-top: 7px;
  border: 1px solid #f5ae19;
  font: normal normal normal 14px/14px Muli;
  border-radius: 10px;
  opacity: 1;
  min-height: 42px;
  margin: 0px 20px 5px 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  color: #4f4f4f;
}

.pdf-botonera-btn_CF {
  background-color: #F3F3F3 !important;
  margin-bottom: 16px !important;
  text-transform: none !important;
  border: 1px solid #C9C9C9 !important;
  border-radius: 10px !important;
  width: "100%" !important;
  justify-content : flex-start !important;
  max-width: 300px !important;
  text-transform: initial !important;
}

.pdf-botonera-btn_CF-active {
  background-color: #F3F3F3!important;
    margin-bottom: 16px !important;
    text-transform: none !important;
    border-radius: 10px !important;
    width: 100% !important;
    justify-content: flex-start !important;
    max-width: 300px !important;
    border: 1px solid #F5AE19 !important;
}
@media screen and (max-width: 600px){
.pdf-botonera-btn_CF {
  font-size: 1rem !important;
  max-width: 100% !important;
  
}

.pdf-botonera-btn_CF-active {
    font-size: 1rem !important;

    max-width: 100% !important;
   
}
}
.pdf-btn {
  background: #e55200 !important;
  margin-top: 32px !important;
  font: normal normal 800 18px/22px Muli !important;
  min-width: 118px !important;
  box-sizing: border-box;
  text-transform: none !important;
  height: 42px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
}

.pdf-btn-default {
  color: #000000 !important;
  font-size: 11pt !important;
  font-weight: 600 !important;
  text-transform: none !important;
  height: 60px !important;
}

.pdf-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 95%;
  border: 1px solid #f5ae19 !important;
  margin-top: 0px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.pdf-container {
  height: 100%;
  padding: 20px;
  width: 100%;
}

.btn-close-sign-popup {
  padding: 0px !important;
  margin-top: -15px !important;
  margin-right: -15px !important;
  width: 20px !important;
  height: 20px !important;
}

.btn-close-sign-popup svg {
  width: 20px !important;
  height: 20px !important;
}

#btn-recipient-close {
  margin-top: 8px !important;
  margin-right: 8px !important;
  background-size: 90% !important;
  height: 2px !important;
}

.btn-close-sign-modal {
  background-image: url(/static/media/icono-cerrar.84dcb6e2.svg);
  background-position: 0 0 !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
  width: 24px !important;
  height: 26px !important;
  margin-top: 4px !important;
  margin-right: 4px !important;
}

#forwardCode {
  margin-top: 7.5px !important;
  margin-left: 10.5px !important;
}

.btn-forward-sign-modal {
  background-image: url(/static/media/icon_forward.6ba9f66a.svg);
  background-position: 0 0 !important;
  background-size: 60% !important;
  background-repeat: no-repeat;
  width: 24px !important;
  height: 26px !important;
  margin-top: 4px !important;
  margin-left: 5px !important;
}

.subtitleOptions {
  font: normal normal 600 16px/22px Muli !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #4f4f4f !important;
  opacity: 1;
}
#lineaProgresoTexto {
  font-size: 11px;
}
#lineaProgresoCaja {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pdf-miniaturas {
  height: 100%;
  margin-bottom: 0;
  background-color: #ededed;
  border-radius: 5px;
  border: #dadada 2px solid;
  border-top: 0px;
}
.pdf-miniaturasCF{
  max-height: 580px;
}

.pdf-miniaturas-firmas {
  margin-top: 7px;
  margin-bottom: 30px;
  overflow-y: auto;
  height: 90%;
  background-color: #ededed;
}

.pdf-miniaturas-firmasCF{
  /* margin-top: 7px; */
  height: auto;
  overflow-y: auto;
  max-height: 500px;
}

.sinMargin {
  margin-bottom: 0;
  margin-top: 3px;
  max-height: 625px;
  padding-bottom: 0;
}

.pdf-canvas-mini {
  display: block;
  border: 1px solid;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pdf-canvas-mini-p {
  text-align: center;
}

.pdf-canvas {
  /* width: 10%;
  max-width: 500px; */
  display: block;
  margin: 0 auto;
  border-top: 1px solid #CCCCCC ;
  border-bottom: 1px solid #CCCCCC;
  /*overflow:auto;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.div-canvas {
  position: relative;
  display: inline-block;
  /*overflow: auto;*/
}

.accordion {
  cursor: pointer;
  margin: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 85%;
  max-width: 240px;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  border: 0px !important;
  color: #4f4f4f;
  background-color: #c6c6c6;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234F4F4F%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
}
.accordion2 {
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  border: 0px !important;
  color: #4f4f4f;
  background-color: #c6c6c6;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234F4F4F%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  font-family: Mulish;
  font-size: 12px;
}

.accordion-firmas {
  position: relative;
  /*background-color: #eee;*/
  color: #444;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.accordion-firmas-img {
  width: 50px;
  background-color: white;
  height: auto;
  padding: 5px;
  border: 1px solid;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.accordion-firmas-img-close {
  cursor: pointer;
  top: 50%;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  font-size: 20pt;
}

.accordion-firmas-img-text {
  top: 50%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.accordion-firmas-img-close:hover {
  color: red;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active,
.accordion:hover {
  margin: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 0px !important;
  border: 0px #706f6f solid !important;
  width: 85%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #706f6f;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  max-width: 240px;
}
.active2,
.accordion2:hover {
  margin-bottom: 10px;
  border-width: 0px !important;
  border: 0px #706f6f solid !important;
  width: 100%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #706f6f;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 -3.49691e-07L8 6L0 6L4 -3.49691e-07Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
}

/* Style the accordion panel. Note: hidden by default */

.panel {
  padding: 0 18px;
  /*background-color: white;*/
  display: none;
  overflow: hidden;
}

#loadingPDF {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(/static/media/loader.3c02dc5b.gif);
  /*url("https://i.stack.imgur.com/MnyxU.gif");*/
  background-repeat: no-repeat;
  background-position: center;
}

.pdf-alert {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 5px 5px #00000080;
  border: 1px solid lightgray;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
}

.pdf-alert-title {
  text-align: center;
  letter-spacing: 0px;
  color: #e55200;
  opacity: 1;
  font-size: 19px;
}

.zoom-button {
  border: 0px;
  border-right: 1px solid #9d9d9c;
  padding-right: 5px !important;
}

.addDocumentToSign {
  background: #ededed;
  padding: 0px !important;
  /*border: #DADADA 2px solid;*/
  border-bottom: 0px !important;
  margin-bottom: -5px !important;
}

.addDocumentToSign button {
  background: #ffffff;
  padding: 0px !important;
  margin-top: 5px;
}

.mini-container {
  height: 100%;
  width: 20%;
}

.fullContainer {
  height: 100%;
  max-height: 560px;
  width: 100%;
}

.botonera-container {
  width: 80%;
  height: 100%;
  padding-left: 15px;
}

.box-botonera {
  display: flex;
  padding-top: 11px;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.pdf-botonera-siguiente {
  width: 10%;
  height: 2%;
}

.pdf-btn-float {
  background: #f5ae19 !important;
  padding: 20px !important;
  color: #ffffff !important;
  font: bold 17px Muli !important;
  text-transform: none !important;
  text-shadow: 2px 3px 0px #00000040 !important;
  width: 160px !important;
  height: 49px !important;
  border-radius: 7px !important;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 0px #00000040;
  visibility: hidden;
}

.editar-container {
  height: 20px;
  width: 100% !important;
  padding: 0px;
}

.box-signer {
  display: none !important;
  position: fixed;
  visibility: visible;
  top: 35%;
  right: 0;
  transform: translate(-55%, 0);
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  background: #f5ae19 !important;
  align-content: center;
  box-shadow: 0px 4px 5px #0000004d !important;
}

.box-signer button {
  display: flex;
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAATCAYAAAD4f6+NAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAJKADAAQAAAABAAAAEwAAAAAax6VQAAACg0lEQVRIDcWUTUhUURTH56mEiWStNAlFQcSVhJmLKAaDwBbpQgwNUolooYtx0cYgmgrCaCG2KFBwURCKgiIi4SJIJXIhgqDIVE6UWqik4wetev7+43MahkGv49cffpx7zzvn3PPuu++6XIck27afwBT0Q84hLWNWlgaeg/QdVmEC0k2yEwj0EFhqEhwlZtyyrIZwP/UuML8NY1AJ7ZAPqTAHO4sCyxCzIqtT6I1T7B7WC6o/BImRsdHmCTi1QzXRHhr4esJjWDSL+RXwwQ24BKvQwE7+xR6daOYENEK4PjLJ2EsX2qGYxELKPQdpUAZFkAvSNHRCEzuzLoepLAqrWDWc3iFpnGdeius86BAXwBnQJ8oDaQGSYAluETuMNRI14whMJieghpaZpBhkqpFJeO/E+rEB+Aqf4A+0wjcYgW4W6MUGxTpnGfzCZzuubb+ugxY4CTe17c3ght3UQzE/hfVLz8JvgW/RWazDKZCNFeX472MH4QEUwmd8d8j5x9jFuATzGHRV9MkF+xNFM8AH0hrUgZqZh2mYAWlhy9iXsafgFejSVE4zxHyeQ29AETXzA1RY0g5osUyYBEnPnsIorEM9DIKkhq+HCjLQTV2GNT7U28nk6dy9A9lRKIYxPkeAZxWM80B3UBNMgAd0Xp+BBV3gIV6f/79INr2pVTAk8l6C1AoDoK0Pvi32BWzAXSVgrzpzjD0CVaFCEQP9ZY/wuSP80aY1vI1fD8g5jxmAn+CFNvDDNWJWeB7P+CJj/X1B4atlsAIf8OuPPDhRvA2kh/A2ONp6sX0vErfXCiyuy0+3sy7CRXDDF3gNRy8a0mfucnZFZgZ0oI9PNKCmdKjV2IE2swluFUpXts+IOAAAAABJRU5ErkJggg==) no-repeat center
    center;
}

.bar-sign {
  display: none !important;
  display: flex;
  flex-direction: row;
  color: #e55200;
}

.bar-sign button {
  padding: 0;
  height: 24px;
  width: auto;
  margin: 15px 15px 0px 15px;
}

.bar-sign h3 {
  margin: 15px 5px 0px 10px;
}

.nameQR::before {
  content: "Código ";
}

.view-container {
  height: 75% !important;
}
.circuito {
  height: 100% !important;
}

@supports (object-fit: cover) {
  .box-signer button {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
@media screen and (max-width: 1279px){
.fullContainer{
  display: none;
}
}
@media screen and (max-width: 1252px) {
  .pdf-paper {
    width: 100%;
    height: 100%;
    border: none !important;
    margin-top: 0px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
  }
  .nameQR::before {
    content: "";
  }
  .pdf-container {
    padding: 0;
  }
  .mini-container {
    display: none !important;
  }
  .botonera-container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 0;
  }
  .pdf-botonera-firmas {
    width: 100%;
  }
  .pdf-botonera-siguiente {
    display: none !important;
  }
  .editar-container {
    display: none !important;
  }
  .drag-container {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .subtitleOptions {
    display: none;
  }
  .pdf-btn-float {
    position: fixed;
    visibility: visible;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .box-botonera {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .gridBotonera {
    grid-gap: 0px;
    flex-direction: column;
  }
  .pdf-botonera-btn {
    width: 112px;
    padding-top: 10px;
    height: 60.19px;
    font-size: 11px;
  }

  .pdf-botonera-btn-active {
    width: 112px;
    padding-top: 10px;
    height: 60.19px;
    font-size: 11px;
  }
  

  .box-signer {
    display: block !important;
  }
  .bar-sign {
    display: flex !important;
    margin: 0;
  }
  .view-container {
    margin-top: 18px !important;
    height: 65% !important;
  }
  .pdf-botonera-firmas {
    max-height: 115px !important;
  }
}

@media screen and (max-width: 991px) {
  .pdf-botonera-firmas {
    padding-top: 5px;
    height: auto !important;
  }
  .pdf-botonera-btn-active {
    margin-left: auto;
    margin-right: auto;
  }
  .pdf-botonera-btn {
    margin-left: auto;
    margin-right: auto;
  }
}
.buttonPC {
     padding-left: 0px;
     padding-right: 5px;
     width: 25px;
     height: 17px;
     box-sizing: border-box;
     ;
}
 
.buttonGooleDriveGet {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAABHNCSVQICAgIfAhkiAAAAglJREFUOE+Vkz9s00AYxb93HowjgbKQCQFSUQUIRSRCyL5T1kKnrmGDiT8SAwMCwQAs/FuKxFI2NsgCbC1qpQzRXcwSVJCKICChrl5qMSQM9occ4Sq5Jk3r8bt3P7/P7xk04THGnGDmcwDeDEuYOXEcp+z7/sa4qxg3DMPQT5KkwcxnhBBrRHTe0n2UUl7cM1Br3QbgM/MzAO+Y2QBwLMC8lHLFhu5wqLWu52sy8980TWcdx7lHRFetyxtBEJQBJMPzEWC323WjKPpORMdyETM3PM+72ev1fgE4aEFvSSlfTAQaY24T0XMrhKhYLB6J4/gGES1aZ388z5upVqtRPt922Ol0Dvf7/Z9EdGjkjcD1IAiWmNlpt9tfieiU5fKVlPLaDqAxZmnadzLGZMku71ajgcMwDE8nSfLFTlIIMef7/uowQGv9AcCCBV1VSs1lswHQGJPFf8ESNZRSdbsWrVbrqBDiBwDX0l9SSr3FhDUGdanVapvjyqu1fgrgjgXcLJVKsxlwnYjK1uFjpdT9Sb/lbvMMOBIGM0fMPCOEeDncx2lwZo4LhcJlaK1LALp5XQBcYeYtIno/DWJt9Ugp9TAP5S4RPSGiT1JK3xjzez/uiCj2PO94pVLZGgCbzeYB13W/AainaToP4MF+3GVbBUHwers2/6tzEkA/TdPPAIp7BTLzulLqbK7/B4Ig6yeLwq91AAAAAElFTkSuQmCC);
     background-repeat: no-repeat;
     background-color: #FFF;
     background-position-x: left;
     background-position-y: center;
     background-size: 25px;
     min-width: 25px;
     padding-left: 25px !important;
     font-size: 15px;
     border: 0px;
     opacity: 1;
     color: #878787;
     filter: alpha(opacity=1);
}

.buttonGooleDriveGet:hover {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAABHNCSVQICAgIfAhkiAAAAglJREFUOE+Nkz9oFEEUxr9vl92z0bPRSlSIBBU5LESuvCpm4XaGKw5iZyr/gIWFKFqojX/SKNjEzk6PA72ZPTgl1oqVRCHiP5C0aRJszl12RzawxznZ5DLV8va937xv3veIbY4Q4hiAMyRfWCkpgJpSaqWslGXBMAzrJDsATpF8B+CslfdWKTW7a6AQ4gPJOoBHaZq+cl33PQB3HGCMCbTWb2zolg6FEHOFTGPM3yzLpl3XvQXgolW84vt+rdvt5k8wOv8BgyCo+L7/DcCRsZxOHMdXPc/7RXLveHGWZdeiKHqyLVAIcZ3kgiVtrVKpHBoOh1ccx3ls/fuTJMnUYDBYK+KjDoMgOOB53k+S+6yiy1rrxXa77cZx/AXACUv6M6XUpS1AKeXipHcSQsySHOxko80OpZQnAXwumeSM1nppHCCE6JGUloolrfVMHiuA+fjPWTd3lFJzti2azeZhx3G+k6xY0PNa65csk1HYpd/vr5aZV0r5EMANC7iaJMl0DlwmWbMK7yulbpfBJsVoD8MYk1tgCsBTkuN+3JFljNlwHOcCW63WwTRNfxR2ybJsHsC64zivJ3VjSb6ntb5bDOUmgAcAPiql6lLK39a2TGJvkDza6/XWN4GNRmNPtVr9CmAuX3qSdyYRrBWcj6Lo+cg2+UcYhsdd1x0aYz4B2L9boDFmWWt9usj/B2UY2PRIrdZ2AAAAAElFTkSuQmCC);
     /*font-size: 16px;
     /*color:#E55200;*/
     background-color: #878787;
     color: #FFF;
}

.buttonDropBoxGet {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAAABHNCSVQICAgIfAhkiAAAAjJJREFUOE91krFrE3EUx7/fIxDut1iXTC1kEJzEBgK9+2Wwq9AhDi6Kth3chCaDxa1xE0ENCB1cTJeCTvofdMnvYii0kzim4qqkDtdUkjx55a6c6eUHx/3uvXff3/t+fo/IrMPDQ3N+fv4SwM1CofBgZWXlVzbvnKsA2CP5JggCfUuaZ7pxzq2JyC7JpSQ2AHDXWvtdv6Moeigi7wGYJO88z3sSBME3/Wa/318aj8e7ANaypyf7PyLyGIAluT2bF5ExgNelUmmHzrl3AJ7miGjoK8ktADdERC0v5tT9FZH1C2tRFG2oLQB+UviT5PMgCPZTDgm/bZLPUnsi8oPkfWttP8voFoBPIvKxWCy+qlarcV6XvV5vcTKZaHfXST6y1v6+YJQWR1FUFpEPJDthGO7NsYqjo6OF0Wi0NZ1Oy8aYZqVSGV4KOefqichCInAgIs1arXacFUwQ7AAoa1xEjj3PuxeG4YDdbrdFUpN5q+37/os4jssk3wJYzbm5IclNFVoG0CF5e45YE0AdwJ28vIh8McZsZGE3ALQAXEt+OAWgtzkwxgzOzs40p6OQrhOSG2EYHvwHOxkDBd5RBiJSJ3lxAQB0yjc9z1sWkbY+xph2CvqKkHOu4ft+Rwtm2YnIMAF7oDj0EGvt57S9S2sa6Ha7mlAWA5LKLm+pFYXetNa2c4V0RuI4bpBUXimrWbETAI1sN1eszQynwl3PqJzmscntaPboKIpWRUQF1WpLB2+OXfwDptIEf/D2j4UAAAAASUVORK5CYII=);
     background-repeat: no-repeat;
     background-color: #FFF;
     background-position-x: left;
     background-position-y: center;
     background-size: 25px;
     min-width: 25px;
     padding-left: 25px !important;
     font-size: 15px;
     border: 0px;
     color: #878787;
}

.buttonDropBoxGet:hover {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAAABHNCSVQICAgIfAhkiAAAAiBJREFUOE91kjFsE0EQRefvyQVpCH0iuUCiQpiWhrSWsueloCGCOAUdkp0CRJfQRUiAJaQUNHEaJGh8vnXjzhUSVKkQpYNoQQ4FsixuBk20Zx32eavdndmZ+W8/qLCstWvGmCMiumGMedDr9X4W43Ec3wZwysyvvfenRCR5HPnGWrttjDkmos1wN2bmuvf+m57jON4honcA1kL8ExE97vf7X/WMRqOxKSLHALaL3XUvIr9F5BGAOwCelcT/Ang1m80OEMfxWwBPFpPC+TMRtUTkOgCVvFGSNxOR3Utp1tpmkHUlJP4Qkedpmr7POSi/MNXTXJ6IfGfm+4PB4MucUaPRuCkiH4nog4i89N7/KZuyXq9vVCqVIwDXptPpw+Fw+OuSUZ7snKuKyImIdNM01R8pXc65dWZuAagC2E+SZDIvZK11xpgTIloPr0ch6WzBHorggIiq4f4MwL0kScYK+xCABpeWiHSMMS/0oYi8IaKtkrQJM+/BOVdj5i6AW2XFmHkfgANwd0WzvjGmWTRk2xhzSERXw4MLZm5GUTQmIjWnTt4qFDsH0EySZPQfbD0o8DCdgnRZllUDuzGAvSzLasaYTpDcyUEvFbLWtqMo6mpCCbtJADtSHNrEe5/kE86l6UUcx0lgoXJqKxygUraUnfe+U1ooeKQNoF1gtVjvnJnbxWmWpBXNGeDuFqpclLEpnWixtXNOJehPjvVH1XirHP8PnCcEfFrt8oYAAAAASUVORK5CYII=);
     /*color:#E55200;*/
     color: #FFF;
}

.contentCloudIconsModal {
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 200px;
     min-height: 300px;
     background: #FFFFFF 0% 0% no-repeat padding-box;
     box-shadow: 0px 5px 5px #00000080;
     border: 0.5718113780021667px solid #E3AC31;
     border-radius: 6px;
     opacity: 1;
     padding: 20px;
     box-sizing: border-box;
}

.contentCloudModal {
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 800px;
     min-height: 440px;
     background: #FFFFFF 0% 0% no-repeat padding-box;
     box-shadow: 0px 5px 5px #00000080;
     border: 0.5718113780021667px solid #E3AC31;
     border-radius: 6px;
     opacity: 1;
     padding: 20px;
     box-sizing: border-box;
}

.buttonOneDriveGet {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAABHNCSVQICAgIfAhkiAAAAZVJREFUOE+VVEFSwkAQnNlcgIv4AvmB5shuafEDtXyAfMBSP6DwA/UDgh8Qq7yLB5Ij4QXiAyzhAie2rcGECjHBZW5bM9s929OzTI4xGAwOiOiSiE7kCjNHzNyp1+vdTRDsgh+GYRPAY0FtT2t9WoTzh2A4HFbn8/ktEUnHEhGAJjNXi0AAtI0xrbz8GkEsw1sB2DsR3VlrvzzP84ioBkBA9wBMjDG7LgSi635OYVf0jmWqxfm+tfZGKXUm53K53PJ9f5K9u3pBLM13DviycyJ6zuakc6XUFQCRU4j7Wuv7dN2KYMMgZYAdItpxMQSAyBjjJ7Ucdy7dNTIAn8wsGvcBfLiAp2qutdbyauIgCKS78zQAgBGAC2Z+YObETc4cAF6MMb/7EgSBDGbt+dbaI6XUU6yrM3BSKLMhol6lUrkWgrFYLYUyJaJm3lC3ZiLqCoFoJV9AEmNmbm/Y3K14lkOezWYdZj5Obi4Wi0PP815dnbNhw0dpm4qPkyUax952tmcOyRRA49/PLgzDrH2dJCqVSpFs9g9z6rJuTSNa2wAAAABJRU5ErkJggg==);
     background-repeat: no-repeat;
     background-color: #FFF;
     background-position-x: left;
     background-position-y: center;
     background-size: 50px!important;
     background-size: 25px !important;
     min-width: 25px !important;
     padding-left: 25px !important;
     font-size: 15px !important;
     ;
     font-weight: lighter !important;
     border: 0px;
     color: #878787 !important;
     text-transform: none !important;
     ;
}

.buttonOneDriveGet:hover {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAABHNCSVQICAgIfAhkiAAAAZBJREFUOE+VVNFRwkAQ3b0UIFYgHYgF6NAA4RgLkAYcpQGFDtAGBBswd/gvdoAViAU4QgG3z1kmYTAmkNxXMrv73t6+t8dU8VhrWwBuiMimJQsAE+/9dB8EV8HvdDp9Y8xTSW7inOuV4fwjsNY2ROSemVtaBGDBzH0iapSBABh574dF8T8E6RjeisAAvAMYG2O+mTkKITSNMQp6QkQr59zxQYI4jrXb03wigKkxZgJAx9RM43MiugNwqf9KliTJKl+7vYGOBsBPAXjW+UtBhysAt0TUYuYmgLn3/mE3b0tQJqSI9LR7IjqqYggiWjjnzrJcTjvX7to5gC8RGUZRNAfwWRF8kyYig9lsNtZvjuN4wsxXuwAAPpj5moge9fp1wFPnOe/9Zl+42+2qMPnrXxDR846gdTkUM2HmgRIsU6tlIGsR0cUqErUWkbpPRzRmZn0CsrMUkdGeza1FshFZRFSHblYZQjiPoui1hnMKSTdaZhFrrS5QtkTLEEKrpj3zJGtmbh987Ky1eftWHdFCN/sXq6S3YNtkQZQAAAAASUVORK5CYII=);
     color: #FFF !important;
}

.buttonOneDrivePut {
     margin: 2px;
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAABHNCSVQICAgIfAhkiAAAAZVJREFUOE+VVEFSwkAQnNlcgIv4AvmB5shuafEDtXyAfMBSP6DwA/UDgh8Qq7yLB5Ij4QXiAyzhAie2rcGECjHBZW5bM9s929OzTI4xGAwOiOiSiE7kCjNHzNyp1+vdTRDsgh+GYRPAY0FtT2t9WoTzh2A4HFbn8/ktEUnHEhGAJjNXi0AAtI0xrbz8GkEsw1sB2DsR3VlrvzzP84ioBkBA9wBMjDG7LgSi635OYVf0jmWqxfm+tfZGKXUm53K53PJ9f5K9u3pBLM13DviycyJ6zuakc6XUFQCRU4j7Wuv7dN2KYMMgZYAdItpxMQSAyBjjJ7Ucdy7dNTIAn8wsGvcBfLiAp2qutdbyauIgCKS78zQAgBGAC2Z+YObETc4cAF6MMb/7EgSBDGbt+dbaI6XUU6yrM3BSKLMhol6lUrkWgrFYLYUyJaJm3lC3ZiLqCoFoJV9AEmNmbm/Y3K14lkOezWYdZj5Obi4Wi0PP815dnbNhw0dpm4qPkyUax952tmcOyRRA49/PLgzDrH2dJCqVSpFs9g9z6rJuTSNa2wAAAABJRU5ErkJggg==);
     background-repeat: no-repeat;
     background-position: center;
     background-size: 25px!important;
     background-size: 25px !important;
     width: 32px !important; 
     height: 32px !important;
}

.buttonOneDrivePut:hover {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAABHNCSVQICAgIfAhkiAAAAZBJREFUOE+VVNFRwkAQ3b0UIFYgHYgF6NAA4RgLkAYcpQGFDtAGBBswd/gvdoAViAU4QgG3z1kmYTAmkNxXMrv73t6+t8dU8VhrWwBuiMimJQsAE+/9dB8EV8HvdDp9Y8xTSW7inOuV4fwjsNY2ROSemVtaBGDBzH0iapSBABh574dF8T8E6RjeisAAvAMYG2O+mTkKITSNMQp6QkQr59zxQYI4jrXb03wigKkxZgJAx9RM43MiugNwqf9KliTJKl+7vYGOBsBPAXjW+UtBhysAt0TUYuYmgLn3/mE3b0tQJqSI9LR7IjqqYggiWjjnzrJcTjvX7to5gC8RGUZRNAfwWRF8kyYig9lsNtZvjuN4wsxXuwAAPpj5moge9fp1wFPnOe/9Zl+42+2qMPnrXxDR846gdTkUM2HmgRIsU6tlIGsR0cUqErUWkbpPRzRmZn0CsrMUkdGeza1FshFZRFSHblYZQjiPoui1hnMKSTdaZhFrrS5QtkTLEEKrpj3zJGtmbh987Ky1eftWHdFCN/sXq6S3YNtkQZQAAAAASUVORK5CYII=);
     color: #FFF !important;
}

.itemOneDriveExplorer {}

.itemOneDriveExplorer:hover {
     background-color: #e0e0e0;
}

.itemOneDriveExplorerActive {}

.contentExplorerOneDrive {
     border: 1px solid #a49b99;
     max-height: 400px;
     overflow-y: scroll;
}

.menuSourceFile li {
     color: #878787;
}

.menuSourceFile li:hover, .menuSourceFile li:hover button {
     background-color: #878787;
     color: #FFF;
}
 
.sign-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 314px;
  min-height: 335px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 14px;
  opacity: 1;
  text-align: center;
  overflow-y: auto;;
}

.dv_resultado
{
   width: 100%;
   background-color: #FFFFFF;
}
.title-sign-draw
{
  text-align: left;
  letter-spacing: 0px;
  color: #E55200;
  opacity: 1;
  font-size: 22px;
}
.button-common-orange-fy
{
    background: #F5AE19 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px #00000040;
    opacity: 1;
    width: 90%;
    height: 45px;
}

.form-control-pop-up
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033;
    border: 0.8387856483459473px solid #706F6F;
    border-radius: 10px;
    width: 100%;
    height: 33px;
    opacity: 1;
}
.lds__containerModal_loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(237,237,237,0.72);
    background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 0.5) 50%, rgba(237, 237, 237, 0.72) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(246, 246, 246, 0.86)), color-stop(100%, rgba(237, 237, 237, 0.72)));
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
    
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    /*Centrar*/
    top: calc(50% - 10%);  
    left: 50%;
    margin-top: 30vh;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #000;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-mar-2020, 16:50:42
    Author     : jose.ochoa
*/
body
{
    background-color: #FFF;

}
.BarHeader
{
    background: #F1F1F1 0% 0% no-repeat padding-box;
    align-content: center;
    height: 60px;
    width: 100%;
}
.BarHeader .content
{
    width: 70%;
    min-width: 944;
    margin: auto !important; 
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    /**background: url("../resources/logoGSE.png");**/
    background-position: right;
    background-repeat: no-repeat;
}

.Header img
{
   width: 100%;
   height: 180px;
}

.tableInfo {
    width: 100%;
    table-layout: fixed;
    font-family: arial, sans-serif;
    margin: 0 30px 0 30px;
    border-collapse: collapse;
}
  
.tableInfo td, th {    
    word-break: break-all;
    text-align: justify;
    padding: 8px;
}

.tableInfo th{
    background: #FFEFD8 0% 0% no-repeat !important;
    opacity: 1;    
    font: normal normal bold 11px/16px Muli;
    letter-spacing: 0px;
    text-transform: capitalize;
    color: #E55200;
    border-spacing: 0px !important;
    border: 1px solid black;
    max-height: 32px !important;
    box-sizing: border-box !important;
    padding: 8px !important;
}

.tableInfo td{
    background: white 0% 0% no-repeat !important;    
    opacity: 1;    
    font: normal normal bold 11px/16px Muli;
    letter-spacing: 0px;
    color: black;
    border-spacing: 0px !important;
    border: 1px solid black; 
    max-height: 32px !important;
    box-sizing: border-box !important;
    padding: 8px !important;
}

.contentPage
{
    width: 100%;
   /**background: #fFF url("../resources/bannerfirmaya3.png"); **/
   background-size: cover;
   background-repeat: no-repeat;
    background-position: 0% 00%;   
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.mainTitle
{
    height: 120px;
    background: #000000 0% 0% no-repeat padding-box;
    mix-blend-mode: normal;
    opacity: 0.3;
    

}
.textTitle
{
    position: absolute;
    top: 75px;
    left: 15%;
    height: 41px;
    width: 70%;
    margin: auto !important;     
    color: #FFFFFF  !important;    
    font-size: 33px;
    padding-top: 10px;    
    box-sizing: border-box;
}
.contentView
{
    width: 100%;
    color: #a49b99;
    font-size: 1rem;
    padding: 15px;
}
.contentHeadText
{
    width: 70%;
    min-width: 500px;
    margin: auto !important; 
}

.contentViewHeader
{
    width: 70%;
    margin: auto !important; 
    color: #a49b99;
    font-size: 1rem;
    padding: 20px;
    box-sizing: border-box;
    background-color: #FFF;
    border:1px solid #F5AE19;
    border-radius: 10px;
    position: relative;
    top:-40px;
    min-height: 600px;
    min-width: 277px;
}
@media (max-width: 350px) {
    .contentViewHeader {
        width: 100%; /* Cambia el ancho para pantallas más pequeñas */
    }
}
.contentView h3{
     color: #a49b99;
     font-size: 1.8rem;
}

.form-control {    
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

.contentCapcha
{
    min-width:233;
    margin-bottom: 10px;
}

#captcha
{
    border-radius: 10px;
    border:1px solid  #eeeeee;
    background-color: #FFF;;
} 
.divContentText
{
    border-radius: 10px;
    background-color: #eeeeee;
    padding: 20px;
    box-sizing: border-box;
} 
.btnClass
{
    border-radius: 10px;
    color: #FFF;
    font-size: 18px;
    padding: 10px;   
    background-color: #F5AE19;
    border: #f5ae19 2px solid ;
} 
.btnClass:hover
{
    background-color: #e46219;
} 

.contentPDF
{
    align-content: center;
    width: 100%;
    min-width: 824px;
    margin: 20px;
 
} 

@media screen and (max-width: 1488px) {
    .captchaMin {
        display: flex;
        grid-gap: 20px;
        gap: 20px;
    }   
} 

@media screen and (max-width: 750px) {

    .contentViewHeader {
        top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contentHeadText {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contentHeadTexts {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
