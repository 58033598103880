@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&family=Noto+Emoji&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&display=swap');

.bodyMiddle {
  background: url('../img/fondo_login.png') no-repeat top right;
  z-index: -1;
}

.bodyMiddle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  height: 161px;
  z-index: -1;
}

.bodyFull {
  background-image: url('../img/fondo_login.png');
  background-position: 0 0 !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
}

.MuiToolbar-root {
  padding: 0px !important;
}

.MuiAvatar-colorDefault {
  background: #9D9D9C 0% 0% no-repeat padding-box !important;
  color: #FFF !important;
  font-size: 20px !important;
  float: right;
}

.makeStyles-avatar-6:hover {
  background-color: #f1f1f1 !important;
}


 

.MuiContainer-root {
   width: 1350px !important;
  min-width: 300px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*@media  only screen and (min-width: 600px)
{
    .MuiContainer-root
    {
        width: 100% !important;
    }
    body,header, #app, .bodyMiddle
    {
            min-width: 1024px;
            background-size: 1024px 400px  !important;
     
    }


}*/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ACACAC;
  border-radius: 3px !important;
}

.textZoneUploadLocal {
  width: 100%;
  margin: 8px 0 25px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.gridValidate {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.gridCades{
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex-direction: row !important;
  margin-top: 20px !important;
}
.paperTableValidate {
  width: 440px;
  padding: 20px 27px;
}

.subtitleRecipient {
  font: normal normal bold 20px/20px Muli;
  margin: 0 0 0 0;
  color: #E55200;
  padding-bottom: 20px;
  padding-top: 20px;
}

.textZoneUpload {
  width: 100%;
  margin: 8px 0 8px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.menuChange li {
  color: #878787;
}

.menuChange li:hover {
  background-color: #878787;
  color: #FFF;
}

.menuChange .MuiMenu-paper {
  width: 215px;
}

.paperValidate {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  padding-bottom: 10px !important;
  box-shadow: 0px 3px 3px #00000080 !important;
  border: 0.2860439717769623px solid #E3AC31 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  box-sizing: border-box !important;
}

#validate {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btnValidate {
  background-color: #F5AE19 !important;
  box-shadow: 0px 3px 0px #00000033 !important;
  width: 124px;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 44px;
  font: normal normal bold 16px/16px Muli !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  padding-right: 72px !important;
  padding-left: 72px !important;
  color: #FFFFFF !important;
  border: 1px solid #F5AE19 !important;
  ;
  text-transform: initial !important;
  ;
}
.btnFirmaCades {
  display: flex !important;
  flex-direction: row !important;
  background-color: #E55200 !important;
  color: #FFFFFF !important;
  padding: 8px 24px !important;
  font-size: 16px !important;
  width: 124px;
  height: 44px;
  text-transform: initial !important;
  border-radius: 8px !important;
}
#clearRecipient {
  text-shadow: none;
  width: 168px;
  height: 41px;
  font: normal normal bold 16px/22px Muli !important;
}

.btnChange {
  background-color: #FFFFFF !important;
  letter-spacing: 1px;
  text-shadow: 1px 0 #F5AE19;
  opacity: 1 !important;
  height: 45px;
  font: normal normal bold 18px/18px Muli !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #F5AE19 !important;
  border: 1px solid #F5AE19 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
}
.btnChange2 {
  background-color: #FFFFFF !important;
  border-radius: 10px !important;
  border: 1px solid #878787 !important;
  color: #878787 !important;
  font-family: Mulish !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  height: 20px;
  line-height: 16px;
  opacity: 1 !important;
  width: 117px;
  text-transform: capitalize !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
}

.textZoneUploadSign {
  text-align: center;
  font: normal normal normal 18px/22px Muli;
  letter-spacing: 0px;
  color: #9D9D9C;
  opacity: 1;
}

.btnPut {
  width: 124px !important;
  font: normal normal bolder 15px/0px Muli !important;
  height: 27px !important;
  border-radius: 7px !important;
  opacity: 1 !important;
  color: #F5AE19 !important;
  border: 1px solid #E3AC31 !important;
  text-transform: initial !important;
  margin: 7px !important;
}

.textZoneUploadO {
  margin: -8px 0 5px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.textZoneUpload span {
  color: #E55200;
  font: normal normal bold 18px/22px Muli;
  letter-spacing: 0px;
}

.iconEncrypt {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
}

.iconEncryptColor {
  background-image: url('../img/iconCifrar.svg')
}

.iconEncryptColor:hover,
.iconEncryptColor:focus {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/icon-bike-red.svg) !important;
}

.btnGeneral {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}
.btnGeneralNew {
  background-color: #FFFFFF !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  opacity: 1;
  color: #E55200 !important;
  /* text-shadow: 0px 2px 0px #00000040 !important; */
  border: none !important;
  
  padding: 9px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: Muli !important;
  border: 1px solid #E55200 !important;	

}

.btnGeneralNewDos {
  background-color: #FFFFFF !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  opacity: 1;
  border: none !important;
  color: #444444 !important;
  padding: 9px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: Muli !important;
  border: 1px solid #706F6F !important;	
}

.orangeIcon {
  color: #f5ae19; 
}

#buttonRecipientBlock:disabled {
  opacity: 0.19
}

.buttonRecipient {
  background-color: #F5AE19 !important;
  box-shadow: 0px 3px 0px #00000033;
  height: 38px;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  padding-left: 16px !important;
  padding-right: 15px !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  font: normal normal bold 16px/22px Muli !important;
  letter-spacing: 0px;
  text-transform: initial !important;
}

.buttonSearchRecipient:disabled {
  opacity: 0.19
}

.buttonSearchRecipient {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  margin-left: 2px !important;
  padding: 0px !important;
  box-shadow: 0px 3px 0px #00000033 !important;
  min-width: 36px !important;
  height: 36px;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  letter-spacing: 0px;
}

.btnPopup {
  background-color: #F5AE19 !important;
  display: none !important;
  letter-spacing: 0.8px;
  margin-top: 7px !important;
  margin-bottom: 29px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  height: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

#apply {
  display: initial !important;
}

.gridDocuments {
  padding-left: 4px;
  padding-right: 10px
}

.btnGeneralResize {
  background-color: #F5AE19 !important;
  margin-bottom: 49px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-left: 5px !important;
}

.btnGeneralSign:disabled,
button[disabled] {
  opacity: 0.4;
}

.tabItems {
  padding-left: 10 !important;
  padding-right: 10 !important;
  min-width: 184px !important;
  text-transform: none !important;
  min-height: 64px !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #4F4F4F !important;
}

.tabItems:hover {
  background: transparent linear-gradient(180deg, #DADADA00 1%, #DADADA 100%) 0% 0% no-repeat padding-box;
  border-bottom: 3px solid #E55200;
  color: #E55200,
}

.btnGeneral18 {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 38px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  margin-top: 14px !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnsMenuMessage {
  padding: 0 !important;
  background-color: transparent !important;
  transition: 0.2s !important;
}

.disabledToolbar {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.wraper {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wraperInput {
  appearance: none;
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  background-color: #ffffff;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid grey;

}

.wraperInput:after {
  font-weight: 900;
  content: "\2714";
  display: none;
}

.wraperInput:checked:after {
  display: block;
  color: orangered;
}

.wraperLabel {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 6020;
  cursor: pointer;
}

.btnContinue {
  background-color: #E55200 !important;
  background: #E55200 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  padding: 5px 25px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  margin-top: 14px !important;
  /* font-weight: bold !important; */
  font-size: 16px !important;
}

.btnContinue:hover {
  background-color: #db4f04 !important;
}

.btnContinue2 {
  user-select: none;
  cursor: none;
  display: none;
  opacity: 0;

}

.messageCircuitoF {
  border: 2px solid gray;
  border-radius: 5px;
}

.checkboxFirmantes {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
}

.checkboxFirmantes__input {
  display: none;
}

.checkboxFirmantes__box {
  width: 14px;
  height: 14px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: -5px;
  flex-shrink: 0;
  background-color: white;
}

.checkboxFirmantes__input:checked+.checkboxFirmantes__box::before {
  content: "\2714";
  /* content: "✔"; */
  color: orangered;
  font-size: "small";

}


.btnAdd {
  background-color: white !important;
  background: white 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #E55200 !important;
  ;
  border: 1px solid #E55200 !important;
  ;
  padding: 5px 25px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  margin-top: 14px !important;
  font-size: 16px !important;
}

.listDocuments {
  list-style-image: url(../img/list.png);
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Mulish;

}
.ulCF{
  margin: "0px"; 
  overflow: "auto"; 
  list-style:"none"; max-width:"100%";
  }
  
.listDocumentsCF{
  list-style-image: url(../img/list.png);
  width: 100%;
  white-space: nowrap;
  list-style-position: inside;
  margin-left: -35px;
  height: 20px;
  font-family: Mulish;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
.btnSubmitPassword {
  background-color: #E55200 !important;
  background: #E55200 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 38px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

#buttonVerify {
  margin-top: 16px !important
}

.btnGeneral18:disabled,
button[disabled] {
  opacity: 0.19;
}

.btnGeneral:disabled,
button[disabled] {
  opacity: 0.4;
}

.btnGeneralSignIcon {
  background-color: #F5AE19 !important;
  margin-top: 10px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 19px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneralSign {
  background-color: #F5AE19 !important;
  margin-top: 10px !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.appBar {
  border-bottom: 3px solid #b27947 !important;
}

.verticalLine {
  border: 1px solid #D6D6D6;
  opacity: 1;
  width: 0px;
  height: 25px;
  margin-left: 20px !important;
}

.verticalLine1 {
  border: 1px solid #D6D6D6;
  opacity: 1;
  width: 0px;
  height: 25px;
  margin-left: 30px !important;
}

.titleDocumentsHome {
  font: normal normal bold 24px/30px Muli !important;
  margin-top: 20px !important;
  color: #E55200;
  letter-spacing: 0px !important;
  margin-left: 2% !important;
  margin-bottom: 0% !important
}
.subtitleDocumentsHome {
  /* font: normal normal bold 24px/30px Muli !important; */
  margin-top: 20px !important;

  letter-spacing: 0px !important;
  margin-left: 2.5% !important;
  margin-bottom: 0% !important
}
@media (max-width: 600px) {
  .titleDocumentsHome {
    font-size: 18px !important;
     margin-left: 2% !important;
  }
}

.titleDocuments {
  font: normal normal bold 20px/20px Muli !important;
  margin-top: 20px !important;
  color: #E55200;
  letter-spacing: 0px !important;
  margin-left: 2% !important;
  margin-bottom: 3% !important
}

.btnDocuments {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  /*background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 28px;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 6px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  font: normal normal bold 12px/12px Muli !important;
  margin-left: 12px !important;
  width: 100% !important;
}

.btnDocuments2 {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  /*background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 28px;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 6px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  font: normal normal bold 12px/12px Muli !important;
  margin-left: 12px !important;
  width: 100% !important;
}



.btnQuick {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  /*background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  height: 28px;
  border-radius: 5px !important;
  padding-left: 6px !important;
  padding-right: 0px !important;
  padding-top: 2px !important;
  white-space: nowrap;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  width: 100% !important;
}

.btnGeneralSign:disabled,
button[disabled] {
  opacity: 0.4;
}

.ZoneUploadContent {
  padding-bottom: 0px !important;
}

.contentZoneUpload {
  border-style: dashed !important;
  border: 2px dashed #9D9D9C;
  border-color: #9D9D9C;
  border-width: 3px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}

.contentZoneUploadList {
  border-style: solid !important;
  margin-top: 10px;
  border-color: #DADADA !important;
  border-width: 2px !important;
  background-color: #FFF;
  height: '100%';
}

.contentZoneUploadList:hover {
  border-style: solid !important;
  border-color: #B2B2B2 !important;
  border-width: 2px !important;
  box-shadow: 0px 2px 3px #00000040;
  background-color: #FFF;
  ;
}

.buttonPlusZoneUpload {
  margin-left: 110% !important;
  margin-top: -37px !important;
}

#paperElectro4 {
  width: 442px;
  min-height: 227px;
}

.titlePopUp {
  text-align: center;
  margin-bottom: 41px !important;
  margin-top: 58px !important;
  font: normal normal bold 18px/21px Muli !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.titlePopUpCertificate {
  text-align: center;
  margin-bottom: 4px !important;
  margin-top: -5px !important;
  font: normal normal bold 18px/21px Muli !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.rangeText {
  text-align: center;
  font: normal normal normal 14px/22px Muli;
  color: #4F4F4F;
  padding-top: 78px;
  opacity: 1;
}

#rangeText2 {
  padding-top: 50px
}

.certificateText {
  padding-left: 4%;
  padding-top: 4px;
  color: #9D9D9C;
}

.selectQR {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 82% !important;
  margin-top: 2px !important;
  opacity: 1;
}

.selectQR div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.selectCustom {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 80% !important;
  padding: 0px !important;
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
}
.selectCustomP {
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 80% !important;
  
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
}

.selectCustomP .MuiButtonBase-root{
 padding:  5px !important;
 
}


.selectCustom div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.selectCustom .MuiInput-underline {
  margin: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  color: #706F6F !important;
}

.selectCustom label {
  display: inline;
}

.MuiInput-underline:before {
  border: 0px !important;
  text-align: left;
  text-justify: left;
}

.MuiInput-underline:after {
  border: 0px !important;
  margin-top: -100px !important;
}

.MuiSelect-icon {
  color: #F5AE19 !important;
}

.selectCustom label {
  margin: 0px !important;
  margin-top: -20px !important;
  padding: 0px !important;
  height: 10px !important;
}

.selectCustom option {
  width: 237px;
  max-width: 237px !important;
}

.selectCustom option:hover {
  background-color: #706F6F;
  color: #FFF;
}

.menu-signs-type li:hover,
.menu-signs-type li:hover button {
  background-color: #878787;
  color: #FFF;
}

.passwordGet {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  max-width: 80% !important;
  padding: 0px !important;
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
  filter: opacity(16%) !important;
}

.opionCustom {
  width: 237px;
  white-space: normal !important;
  text-transform: unset !important;
}

.opionCustom:hover {
  background-color: #706F6F !important;
  color: #FFF !important;
}

/*.Mui-selected {
  border: 0px !important;
  background: #706F6F !important;
  color: #FFF !important;
}*/

#paperToken {
  min-height: 215px;
}

.titlePopUpForwardMail {
  text-align: center;
  margin: 14px 0px 9px !important;
  font: normal normal bold 18px/21px Muli !important;
  line-height: 30px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #e55200 !important;
  opacity: 1;
}
.sign-paper-source-signature {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  min-height: 321px;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center !important;
  justify-content: center !important;
  overflow-y: auto;
  outline: none;
}
#paperElectro1 {
    width: 442px;
    min-height: 452px;
}
#paperElectro2 {
   width: 442px;
  min-height: 296px;
}
@media (max-width: 600px) {
 #paperElectro1 {
  width: 90% !important;
  min-height: 482px;
}

#paperElectro2 {
  width: 90%;
  min-height: 296px;
}
}



.paperRecipient {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 187px;
  max-height: 548px;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 3px #00000080;
  border: 0.2860439717769623px solid #E3AC31;
  border-radius: 8px !important;
  opacity: 1;
  overflow-y: auto;
  outline: none;
}

.mainContentPaper {
  box-sizing: border-box !important;
  padding: 20px !important;
  padding-bottom: 0px !important;
  margin-Top: -25px;
  margin-bottom: 25px;
  /*max-width: 1024px !important;*/
  min-width: 424px !important;
  min-height: 380px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.contentPaperPlan {
  padding: 15px;
  margin-right: 20px;
  margin-left: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
  border-radius: 10px !important;
}

.contentPaperDocuments {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
  border-radius: 10px !important;
  width: 98%;
}

.contentPaperDocuments2 {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  /* margin-top: 40px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
  border-radius: 10px !important;
  width: 98%;
}


.mainContentPaperDocuments {
  box-sizing: border-box !important;
  border: 1px solid #E3AC31 !important;
  padding: 0px !important;
  margin-Top: -25px;
  margin-left: 2%;
  margin-right: 2%;
  /*min-width: 1024px !important;*/
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.contentDocumentSigned {
  padding: 7px !important;
  padding-top: 6px !important;
}

/* Aquí */

.contentDocumentSignedHome2 {
  padding: 0px !important;
  margin: 0;
  /* padding-top: 7px !important; */
}

.contentDocumentSignedHome {
  padding: 20px !important;
  padding-top: 7px !important;
}

.containerTabSignedDocumentHome {
  /* height: 100%; */
  width: 100%;
  /* overflow: auto; */
  /* box-sizing: border-box; */
}

/* Fin */

.searchBar {
  background-color: #EDEDED;
  height: 72px;
  width: 100%;
  padding: 15px;
  ;
  box-sizing: border-box;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.validateSubtitle {
  font: normal normal bold 12px/15px Muli;
  text-shadow: 0px 0px 0px #EEEEEE !important;
  color: #4F4F4F !important;
  font-size: 14px !important;
  letter-spacing: 0.2px;
}

.styleUser {
  backdrop-filter: brightness(40%);
}

.styleUser .MuiMenu-paper {
  width: 100%;
  max-width: 380px;
  max-height: 100%;
  height: 100%;
  background: #FFFFFF center 98% no-repeat padding-box;
  background-image: url(../img/logoGse.PNG);
}

.tableResponsive {
  height: 80%;
  padding-bottom: 20%;
  width: 100%;
  overflow: scroll;
  position: fixed;
  background-color: white
}

.recipientSearch {
  width: 454px !important;
  box-shadow: 0px 3px 0px #00000033 !important;
  height: 36px;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #706F6F !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
}

.fieldSearch {
  width: 68%;
  height: 39px;
  background-image: url(../img/search.png);
  background-color: #FFF;
  background-position-y: 50% !important;
  background-repeat: no-repeat, no-repeat;
  background-position: 1%, 98%;
  border: 1px solid #C6C6C6 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  padding-left: 32px !important;
  padding-right: 25px !important;
}

.fieldSearchResponsive {
  width: 100%;
  height: 39px;
  background-image: url(../img/search.png);
  background-color: #FFF;
  background-position-y: 50% !important;
  background-repeat: no-repeat, no-repeat;
  background-position: 1%, 98%;
  border: 1px solid #C6C6C6 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  padding-left: 32px !important;
  margin-right: 8px !important;
}

.checkButtonDocumentSigned input[type=checkbox] {
  border: 1px solid #9D9D9C !important;
}



.headerTableValidate {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  font: normal normal bold 11px/16px Muli;
  color: #E55200;
  padding: 10px !important;
}

.headerTableValidate tr th,
.headerTableValidate tr {
  padding-bottom: 11px !important;
  padding-top: 11px !important;
}

.headerTableDocumentSigned {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal bold 11px/16px Muli;
  letter-spacing: 0px;
  color: #E55200;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 3px !important;
}

.headerTableDocumentCircuit {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal bold 11px/16px Muli;
  letter-spacing: 0px;
  color: #E55200;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 0px !important;
}

#tableUsers tr th:first-child {
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  border-spacing: 0px !important;
  border: 0px !important;
}

#tableUsers tr th:last-child {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-spacing: 0px !important;
  border: 0px !important;
}

#tableUsers tr th,
.headerTableDocumentSigned tr {
  padding: 7px !important;
}

.headerTableDocumentSigned tr th,
.headerTableDocumentSigned tr {
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 6px !important;
}

.headerTableDocumentSigned tr {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
}

.headerTableDocumentSigned tr th:first-child {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.headerTableDocumentSigned tr th:last-child {
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.modal-signer {
  width: 489px !important;
}

.tableSignedDocumentsCF tr {
  width: 100% !important;
  box-sizing: border-box !important;
}

.borderActive {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
}

.tableSignedDocumentsCF tbody tr:hover {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
}


.tableSignedDocumentsCF .Mui-selected {
  background: #ffffff !important;
  color: #000 !important;
}

.tableSignedDocuments {
  width: 99% !important;

}

.tableSignedDocuments tr {
  width: 100% !important;
  box-sizing: border-box !important;
  border: 1.4px #ffffff solid !important;
}
 #rowFolder {
  width: 100% !important;
  box-sizing: border-box !important;
  border: 1.4px #ffffff solid !important;
  border-bottom: hidden !important;
}


#rowFolder:hover {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
  border-bottom: hidden !important;
}
.tableSignedDocuments tbody tr:hover {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
}
.tableSignedDocuments tbody tr:hover:first-child {
  border-top: 1.4px #ffffff solid !important;
}


.tableSignedDocuments .Mui-selected {
  border: 1.4px #B2B2B2 solid !important;
  box-shadow: 0px 2px 2px #00000040 !important;
  background: #ffffff !important;
  color: #000 !important;
}

.containerTabSignedDocument {
  height: 300px;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  /*Estilos estÃ¡ndar experimentales (Principalmente Firefox)*/
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: 10px !important;
}

.containerTabSignedDocument::-webkit-scrollbar {
  -webkit-appearance: none;
}

.containerTabSignedDocument::-webkit-scrollbar:vertical {
  width: 10px !important;
}

.containerTabSignedDocument::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none !important;
}

.containerTabSignedDocument::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

.containerTabSignedDocument::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

.containerTabSignedDocument::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

.selectOrderBy {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: .4em 1.4em .3em .8em;
  width: 130px;
  box-sizing: border-box;
  border: 0px solid #aaa;
  border-radius: .3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23878787%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #fff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

/***EXPLORER */

.buttonGoogleDriveExplorerBlack {
  text-transform: capitalize !important;
  background-color: transparent !important;
  padding-top: 8px !important;
  padding-bottom: 11px !important;
}

.buttonGoogleDriveExplorer {
  background-image: url(../img/driveIcon.png);
  background-Position: center;
  border-radius: 5px !important;
  width: 28px !important;
  margin-left: 20px !important;
  height: 28px !important;
}

.buttonGoogleDriveExplorerCF {
  background-image: url(../img/driveIcon.png);
  background-Position: center;
}

.buttonOneDrive {
  margin: 2px;
  background-Repeat: no-repeat;
  background-Position: center;
  width: 32px !important;
  height: 32px !important;
  border: 0px solid;
}

.buttonDropbox {
  margin: 2px;
  background: #FFF url(../img/DropboxIcon.png);
  background-Repeat: no-repeat;
  background-Position: center;
  width: 32px !important;
  height: 32px !important;
}

.buttonDropbox:hover {
  background-Image: url(../img/DropboxIconHover.png);
}

/*********************/

.onedrive-paper {
  display: block;
  left: 50%;
  min-width: 100%;
  min-height: 40%;
  max-height: 100%;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
}

.breadcrumbOneDriveExplorer {
  height: 30px;
  color: #000;
  border: 0px;
  width: 100%;
  border-bottom: 1px #706F6F solid;
}

.barcontrolOnedriveExplorer {
  margin-bottom: 20px;
  ;
}

.buttonOneDriveExplorer {
  background: #f4f4f4 !important;
  border-radius: 2px !important;
  margin-left: 5px !important;
  font-size: 12px;
  text-transform: capitalize !important;
}

.buttonOneDriveExplorerActive {
  border-radius: 2px !important;
  background: #106ebe;
}

.buttonOneDriveExplorer:hover {
  background: #106ebe !important;
  color: #FFF !important;
}

.titleModalOneDriverExplorer {
  font-size: 18px;
  color: #000;
}

.tableFoldersOneDrive {
  color: #000;
  ;
}

.tableFoldersOneDrive thead {
  color: #f36a00 !important;
  background: #FFEFD8;
}

.tableFoldersOneDrive tbody tr:hover {
  background: #f4f4f4;
}

.newFolderModel-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  min-height: 180px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 14px;
  opacity: 1;
  text-align: center !important;
  justify-content: center !important;
  overflow-y: auto;
  outline: none;
}

.active-sheet {
  -webkit-box-shadow: 2px 0px 15px 1px rgba(0, 0, 0, 0.77) !important;
  box-shadow: 2px 0px 15px 1px rgba(0, 0, 0, 0.77) !important;
}

.firmante {
  width: 44px;
  height: 30px;
  background-color: violet;
}

.firmante button {
  background-image: url("../img/ico-firmante.png");
}

.btnFirmante {
  content: none;
  background-image: url("../img/ico-firmante.png");
  min-width: 5px;
  height: 23px;
}

.btnFirmante:hover {
  content: none;
  background-image: url("../img/ico-firmante-active.png");
}

.box {
  float: right;
  margin-right: -4px !important;
  position: relative;
  overflow: hidden;
}

.box button {
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url("../img/ico-firmante.png") no-repeat center center;
}

.box button:hover {
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url("../img/ico-firmante-active.png") no-repeat center center;
}

.inputText .MuiOutlinedInput-root {
  height: 33px;
  margin-bottom: 11px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #00000033;
  color: #9D9D9C !important;
  border-radius: 10px;
  opacity: 1;
}

.btnGeneralTSA {
  background-color: #F5AE19 !important;
  background: #F5AE19 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  text-transform: initial !important;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
  width: 100%;
  height: 33px;
}

.checkbox-label {
  margin: 5px 5px 0 0;
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  margin: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
}

.checkbox-custom {
  position: absolute;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #707070;
}

.seleccionada {
  position: relative;
  border-radius: 5px;
  opacity: 1;
  width: 220px;
  height: 123px;
}

.checkbox-label input:checked~.checkbox-custom {
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: rotate(0deg) scale(1);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 2px #00000029;
}

.checkbox-label input:checked~.seleccionada {
  background-color: lawngreen;
  border-radius: 5px;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  border: 3px solid #98C500;
  transform: rotate(0deg) scale(0);
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
}

.checkbox-label input:checked~.checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  right: 6px;
  top: 2.5px;
  width: 6px;
  height: 11px;
  border: solid #98C500;
  border-width: 0 3px 3px 0;
  background-color: transparent;
}

.drag-sign {
  display: block;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.load-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.drag-sign img {
  box-shadow: 0px 5px 10px #68666680;
}

.drag-sign p {
  text-align: center;
  font: normal normal normal 12px/24px Muli;
  letter-spacing: 0px;
  color: #E55200;
}

.sign-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 75px;
}

.sign-container img {
  height: calc(80%);
}

.firma-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 47px;
}

.firma-container img {
  height: calc(100%);
  width: 100px
}

.show-loading {
  position: fixed;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url('../img/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.container-sign {
  position: absolute;
  background: #EDEDED 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 1px solid #878787;
  /*height: 50px;
  width: 50px;
  top: 30px;
  left: 30px;*/
}

.resizer {
  width: 12px;
  height: 12px;
  /*border-radius: 50%;
  background: white;
  border: 1px solid #878787;*/
  position: absolute;
  background: transparent url('../img/resize.png');
  border: none;
}

.resizer.nw {
  top: -2.5px;
  left: -2.5px;
  cursor: nw-resize;
}

.resizer.ne {
  top: -2.5px;
  right: -2.5px;
  cursor: ne-resize;
}

.resizer.sw {
  bottom: -2.5px;
  left: -2.5px;
  cursor: sw-resize;
}

.resizer.se {
  bottom: 2px;
  right: 2px;
  cursor: se-resize;
}

.container-sign canvas {
  height: calc(100%);
  width: calc(100%);
  /*border-radius: 12px;
  border: 2px solid #878787;*/
}

.container-sign button {
  position: absolute;
  border-radius: 50%;
  padding: 0.5em;
  width: 21px;
  height: 21px;
  /*border: 2px solid #F5AE19;
  background-color: #FFFFFF;
  color: #F5AE19;*/
  cursor: pointer;
  outline: none;
  background: transparent url('../img/close-sign.png') 0% 0% no-repeat padding-box;
  border: none;
  top: -10px;
  right: -10px;
}

[data-title]:hover:after {
  border-radius: 3px;
  opacity: 1;
  /*transition: all 0.1s ease 0.5s;*/
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #706f6f;
  color: #FFF;
  font: normal normal normal 11px Muli;
  /*font-size: 50%;*/
  position: absolute;
  padding: 3px 6px 3px 6px;
  top: -2.5em;
  /*bottom: -1.6em;*/
  /*left: 100%;*/
  white-space: nowrap;
  /*box-shadow: 1px 1px 3px #222222;*/
  opacity: 0;
  /*border: 1px solid #111111;*/
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.menu-tabs {
  position: relative;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF99 100%) 0% 0% no-repeat padding-box;
  background-color: #FFFFFF;
  opacity: 1;
  height: 80vh;
  width: 20%;
}

.tab-panel {
  width: 80%;
}

.Mui-selected {
  /*background: rgb(237, 237, 237);
  background: transparent linear-gradient(180deg, #DADADA00 1%, #DADADA 100%) 0% 0% no-repeat padding-box;
  border-bottom: 3px solid #E55200 !important;
  color: #E55200 !important;*/
  color: #E55200 !important;
}

.verticalTabsItems {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #6D6E71;
  background-color: #fff;
  opacity: 1;
}

.verticalTabsItems:hover {
  color: #E55200 !important;
  font-weight: bold !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.over-list {
  border-radius: 10px;
  border: 1px solid #B2B2B2;
  background-color: #FFFF;
}

.dialog-container {
  width: 450px;
  max-height: 300px;
  margin: 20px;
  display: 'flex';
  flex-direction: 'row';
  overflow-y: scroll;
}

.tableDocumentSigned {
  width: 100%;
}

.space-between {
  display: none !important;
}

.btn-close-popup {
  position: absolute;
  padding: 0px !important;
  float: right;
  top: 2px;
  right: 2px;
  width: 20px !important;
  height: 20px !important;
  display: none !important;
}

.btn-close-popup svg {
  width: 20px !important;
  height: 20px !important;
}

.paper-sign {
  position: relative !important;
  background: #F3F3F3 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 4px #00000040 !important;
  border-radius: 5px !important;
  width: 220px !important;
  height: 123px !important;
  margin: auto !important;
}

.grid-sign {
  padding-left: 12px;
  padding-bottom: 12px;
}

.icon-button {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.icon-button span {
  display: none;
  font-size: 10px;
  text-align: center;
  height: auto;
  width: 38px;
  color: #4F4F4F;
}

.icon-limpiar span {
  margin-top: 25px;
}

.icon-anadir span {
  width: 200px;
  margin-top: 30px;
}

.icon-anadir {
  margin-left: 10px !important;
  background-image: url('../img/ico-anadir.png');
}

.icon-anadir:hover {
  background-image: url('../img/ico-anadir-sel.png');
}

.icon-limpiar {
  background-image: url('../img/ico-limpiar.png');
}

.icon-limpiar:hover {
  background-image: url('../img/ico-limpiar-sel.png');
}

.button-sign {
  width: 40px;
  height: 40px;
  background: #EDEDED 0% 0% no-repeat padding-box !important;
  background-position: center !important;
  box-shadow: 0px 2px 0px #00000033 !important;
  border: 0.6078676581382751px solid #4F4F4F !important;
  border-radius: 5px !important;
  margin-right: 16px !important;
}

.button-sign span {
  display: none;
}

.button-sign-sel {
  width: 40px;
  height: 40px;
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-position: center !important;
  box-shadow: 0px 2px 0px #00000033 !important;
  border: 0.6078676581382751px solid #4F4F4F !important;
  border-radius: 5px !important;
  margin-right: 16px !important;
  border: none !important;
}

.button-sign-sel span {
  display: none;
}

.button-cargar {
  background-image: url('../img/ico-cargar.png') !important;
}

.button-cargar-sel {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-image: url('../img/ico-cargar-sel.png') !important;
  border: none !important;
}

.button-dibujar {
  background-image: url('../img/ico-manuscrita.png') !important;
}

.button-dibujar-sel {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-image: url('../img/ico-manuscrita-sel.png') !important;
  border: none !important;
}

.button-digital-sel span {
  margin-top: 10px;
  margin-left: -11px;
  margin-bottom: 2px;
}

.button-digital {
  background-image: url('../img/ico-predefinido.svg') !important;
}

.button-digital-sel {
  background: #F5AE19 0% 0% no-repeat padding-box !important;
  background-image: url('../img/ico-predefinido-sel.svg') !important;
  border: none !important;
}

.button-sign-container {
  margin-top: 40px !important;
  width: 170px !important;
}

.gridButtons {
  direction: row;
  justify-content: flex-end;
}

.label-sign {
  position: absolute;
  font: normal normal bold 15px/10px Muli;
  letter-spacing: 0px;
  color: #FFFFFF;
  width: 70%;
  text-align: center;
  text-shadow: 0px 0px 6px #000000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 1;
}

.title-background {
  padding-left: 2%;
  margin: 0;
  color: white;
  height: 100px;
  padding-bottom: 90px !important;
  padding-top: 20px;
}

.title-background-home {
  margin: 0;
  height: 100px;
  padding-top: 20px;
  width: 100%;
  
}

.title-background-home h1 {
  
  margin: 0;
  color: white;
}

.home-upload-grid {
  margin-top: -50px !important;
  margin-bottom: 30px;
}

.zone-upload-container {
  max-width: 1350px;
  margin-top: -35px !important;
  padding-left: 2%;
  padding-right: 2%;
}

#paper-upload-result {
  padding-top: 35px !important;
  padding: 25px;
}

.paper-upload {
  padding: 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
}

.paper2-upload {
  padding: 15px 35px 35px 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
}
.addSigner {
  padding: 15px 35px 35px 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #F5AE19;
}
@media (max-width: 600px) {
  .addSigner {
    padding: 10px 15px 20px 15px !important; /* Reduce el padding en móviles */
    max-width: 98%; /* Reduce el ancho máximo para ajustarse mejor en pantallas pequeñas */
  }
}
.validate-style-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  padding: 20px;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  min-height: 340px;
  background-color: #EDEDED;
  border: 2px dashed #9D9D9C;
  cursor: pointer;
  box-sizing: border-box;
}


.icon-upload {
  width: 54px;
}

.base-validate-container {
  flex: 1;
  height: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px !important;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  background-color: #EDEDED;
  border: 2px dashed;
  cursor: pointer;
  box-sizing: border-box;
}

#containerRecipient {
  margin-bottom: 30px !important;
  min-height: 120px;
}

.base-style-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px !important;
  padding: 20px;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  min-height: 340px;
  background-color: #EDEDED;
  border: 2px dashed;
  cursor: pointer;
  box-sizing: border-box;
}

#base-container {
  padding-top: 124px !important;
}

.box-botonera {
  display: inline-block;
  /* margin-top: 20px; */
}

.input-certificate {
  margin-top: 4px !important;
  cursor: pointer;
  height: 18px;
  width: 18px;
  background-color: white !important;
  margin: auto;
  border: 1px solid #707070;
  -webkit-appearance: none;
  border-radius: 3px !important;
  position: relative;
}

#encryptContainer {
  min-height: 249px;
}

#decryptContainer {
  padding-top: 17px !important;
  padding-bottom: 40px !important;
  min-height: 100px;
}

#decryptContainerOption {
  padding-top: 14px !important;
  padding-bottom: 19px !important;
  min-height: 2px;
}

#headerTableCertificate th {
  padding: 10px 3px !important;
  max-height: 40px !important;
}

.checkmark-title {
  margin-top: 8px !important;
  font: normal normal normal 15px/10px Muli;
  color: #4F4F4F !important;
  font-size: 14px !important;
  padding-left: 8px;
  position: absolute !important;
}

.input-certificate:checked {
  background-color: #E55200 !important;
  border: 1px solid transparent;
}

.input-certificate:checked:after {
  content: '\2714';
  font-size: 13px;
  position: absolute;
  color: white;
  top: -1.9px;
  left: 3px;
}

.isLoad {
  margin-top: 15px
}

.box-list-signs {
  display: inline-block;
  height: 300px !important;
  overflow-x: hidden;
}

.titlesMenu {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: -15px;
  font-size: 15px;
}

.wordActive {
  color: #0294C2;
  text-align: center;
  max-width: 90px;
}

@media screen and (max-width: 1200px) {
  .wordActive {
    margin-left: -10px;
  }
}

@media screen and (max-width: 630px) {
  .titlesMenu {
    width: 65%;
  }
  .gridCades{
    align-items: stretch !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 600px) {
  .btnFirmaCades {
    width: 89vw;
  }
  .btnGeneralNewDos{
    width: 89vw;
  }
}

@media screen and (max-width: 584px) {
  .titlesMenu {
    width: 70%;
  }
}

@media screen and (max-width: 534px) {
  .titlesMenu {
    width: 80%;
    min-width: 327px;
  }
}

@supports(object-fit: cover) {
  .box button {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media screen and (max-height: 610px) {
  .styleUser .MuiMenu-paper {
    background: #FFFFFF center 0% no-repeat padding-box;
    background-image: url(../img/logoGse.PNG);
    background-position-y: 550px;
  }
}

@media screen and (max-width: 765px) {
  .bodyMiddle {
    background: none;
  }

  .bodyFull {
    background-image: none;
  }

  .bodyMiddle::after {
    background-color: initial;
  }

  #titleValidate {
    margin-top: -37px !important;
    visibility: hidden;
  }

  .title-background {
    padding-left: 2%;
    height: 80px;
    padding-bottom: 0px !important;
    font: normal normal bold 20px/20px Muli !important;
    color: #E55200;
    margin: 0 25px 0 20px
  }
}

@media screen and (max-width: 505px) {
  .btnGeneralSign {
    font-size: 16px !important;
    height: 47px !important;
    letter-spacing: 1px;
    border-radius: 9px !important;
    width: 219px;
  }

  .btnValidate {
    width: 455px !important;
  }

  .btnFirmaCades {
    width: 87vw;
  }

  .btnGeneralNewDos{
    width: 87vw;
  }



  .gridButtons {
    justify-content: center
  }

  .btnGeneralSignIcon {
    font-size: 16px !important;
    padding-left: 22px !important;
    height: 47px !important;
    letter-spacing: 1px;
    padding-right: 0px !important;
    border-radius: 9px !important;
    width: 219px;
  }
}

@media screen and (max-width: 600px) {
  .menuChange .MuiMenu-paper {
    border: 1.25px solid #9D9D9C;
    width: 455px;
  }

  .paperTableValidate {
    width: 360px;
    padding: 15px;
  }

  .validate-style-container {
    padding-bottom: 32px !important;
    height: 148px !important;
    padding-top: 32px !important;
  }

  .btnChange {
    width: 455px !important
  }

  .gridValidate {
    align-items: flex-start !important;
    flex-direction: column-reverse !important;
  }

  .contentPaperPlan {
    margin-top: 10px;
  }

  .btnDocuments {
    height: 30px;
    padding-right: 10px !important;
    padding-left: 10px !important;
    font: normal normal bold 12px/12px Muli !important;
  }

  
  .btnDocuments2 {
    height: 30px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    font: normal normal bold 12px/12px Muli !important;
  }

  .btnQuick {
    height: 30px;
  }
}

@media screen and (max-width: 1279px) {
  .btnContinue {
    display: none !important;
  }

  .btnContinue2 {
    display: flex;
    opacity: 1;
    cursor: pointer;
    background-color: #E55200 !important;
    justify-content: center;
    align-items: center;
    background: #E55200 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033 !important;
    -webkit-box-shadow: 0px 3px 0px #00000033 !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    color: #FFFFFF !important;
    text-shadow: 0px 2px 0px #00000040 !important;
    border: none !important;
    padding: 8px 24px !important;
    margin-bottom: 20px;
    text-transform: initial !important;
    margin-top: 14px !important;
    font-size: 17px !important;
  }

  .btnContinue2:hover {
    background-color: #db4f04 !important;
  }
}

@media screen and (max-width: 1250px) {

  .tableDocumentSigned {
    border-collapse: separate;
    border-spacing: 10px 0;
  }



  .tableDocumentSigned td {
    padding: 15px 0;
  }

  .tableDocumentSigned tr {
    border-bottom: 1px solid #D6D6D6;
    border-top: 1px solid #D6D6D6;
  }

  .tableDocumentSigned tr>* {
    width: 80vw;
    display: inline;
    border-bottom: none;
    text-transform: capitalize;
    padding: 10px 0 0 0;
    text-align: left;
  }

  .tableDocumentSigned tr td:first-child {
    display: inline-block;
    font-weight: bold;
    padding-bottom: 2px;
    width: 100% !important;
  }

  .modal-signer {
    top: 25% !important;
    left: 3% !important;
    width: 94% !important;
  }

  .dialog-container {
    width: 100%;
    position: relative;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 330px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 991px) {
  .rangeText {
    display: none !important;
  }

  .menu-tabs {
    display: none;
  }

  .firma-container img {
    height: calc(80%);
    width: calc(42%)
  }

  .buttonPlusZoneUpload {
    margin-left: 37% !important;
    margin-top: -17px !important;
  }

  #apply {
    display: initial !important;
    margin-top: 22px !important;
    margin-bottom: 20px !important;
  }

  .btnPopup {
    display: initial !important;
    margin-top: 11px !important;
  }

  .title-modal {
    padding-left: 17px;
  }

  .isLoad {
    margin-bottom: 10px
  }

  .btnPut {
    width: 154px !important;
    height: 41px !important
  }

  .contentPaperDocuments {
    margin-left: 3%;
    width: 94%
  }

  .tableSignedDocuments tbody tr:hover {
    border: none !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: none !important;
  }

  .tableSignedDocuments .Mui-selected {
    border: none !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: none !important;
  }

  .tab-panel {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .over-list {
    border: 0.5px solid #E3AC31 !important;
    border-radius: 5px;
  }

  .space-between {
    display: block !important;
  }

  .btn-close-popup {
    display: inline !important;
    background-image: url('../img/icono-cerrar.svg');
    background-position: 0 0 !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
    margin: 5px !important;
    width: 24px;
    height: 26px;
  }

  .paper-sign,
  .seleccionada {
    width: 190px !important;
    height: 70px !important;
    padding: 0 !important;
  }

  .paper-sign img {
    width: calc(20%);
    height: auto;
  }

  .MuiBox-root,
  .MuiBox-root-111 {
    padding-top: 7px !important;
  }

  .grid-sign {
    padding-top: 10px !important;
  }

  .checkbox-label,
  .checkbox-input,
  .checkbox-custom {
    position: absolute !important;
    top: 0;
    width: 15px;
    height: 15px;
  }

  .checkbox-label input:checked~.checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    right: 4px;
    top: 2.4px;
    width: 4px;
    height: 7px;
    border: solid #98C500;
    border-width: 0 2px 2px 0;
    background-color: transparent;
  }

  #icon-delete {
    position: absolute !important;
    bottom: 0;
    width: 13px !important;
    height: 17px !important;
  }

  #icon-delete img {
    width: 13px !important;
    height: 17px !important;
  }

  .sign-container {
    width: calc(100%) !important;
  }

  .sign-container img {
    width: calc(41%) !important;
    height: calc(65%) !important;
  }

  .box-list-signs {
    margin-top: 15px;
    max-height: 180px !important;
  }

  .load-sign {
    width: 100%;
    margin-top: -10px !important;
    margin-bottom: -5px !important;
  }

  .btn-panel {
    display: inline-block;
    margin: 0 !important;
  }

  .btn-panel button {
    width: 90px;
    max-width: 106px;
    height: 61px;
    padding: 0;
    margin: 0;
    margin: 5px;
  }

  .btnGeneral {
    border-radius: 7px !important;
    height: 40px !important;
  }

  .btnGeneralResize {
    border-radius: 7px !important;
    margin-bottom: 38px !important;
    height: 40px !important;
    margin-top: 40px !important;
    width: 182px !important;
    height: 45px !important;
  }

  .button-sign-container {
    margin-top: 17px !important;
    width: 103.7% !important;
  }

  .button-sign {
    padding: 5px !important;
    height: 61px;
    width: 90%;
    background-position: top !important;
    box-shadow: 0px 2px 0px #00000033 !important;
  }

  .button-sign span {
    display: table-cell;
    color: #4F4F4F;
    vertical-align: bottom;
    text-align: center !important;
    font: Muli;
    font-size: 11px;
    font-weight: 600;
    height: 40px;
    width: 70px;
  }

  .icon-button:hover:after {
    content: attr(data-title);
    visibility: hidden
  }

  .button-sign-sel:hover:after {
    content: attr(data-title);
    visibility: hidden
  }

  .button-sign:hover:after {
    content: attr(data-title);
    visibility: hidden
  }

  .button-sign-sel {
    padding: 5px !important;
    height: 61px;
    width: 90%;
    background-position: top !important;
    box-shadow: 0px 2px 0px #00000033 !important;
  }

  .button-sign-sel span {
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    font: Muli;
    font-size: 11px;
    font-weight: 600;
    height: 40px;
    width: 70px;
    color: #FFFFFF;
  }

  .button-digital-sel {
    background-image: url('../img/ico-predefinido-sel.png') !important;
    background-position: 50% 20% !important;
  }

  .button-digital {
    background-image: url('../img/ico-predefinido.png') !important;
    background-position: 50% 20% !important;
  }

  .button-digital span {
    margin-top: 10px;
    margin-left: -11px;
    margin-bottom: 2px;
  }

  .button-cargar-sel,
  .button-dibujar-sel {
    background-position: top !important;
  }

  .icon-limpiar:hover {
    background-image: url('../img/ico-limpiar.png');
  }

  .icon-anadir:hover {
    background-image: url('../img/ico-anadir.png');
  }

  .label-sign {
    font: normal normal bold 11px Muli;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 6px #000000;
    top: 43%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 1;
  }

  .paper-upload {
    width: 100%;
    padding: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .icon-upload,
  .textZoneUpload,
  .textZoneUploadO {
    display: none !important;
  }

  .textZoneUploadLocal {
    width: 90%;
    font-size: 17px;
    margin: 27px 0 15px 0;
  }

  .base-validate-container {
    padding-top: 0px !important;
    height: 168px !important;
    border-radius: 10px !important;
  }

  .base-style-container {
    min-height: 148px;
    border-radius: 10px;
  }

  .btnGeneral {
    font-size: 16px !important;
    border-radius: 9px !important;
    width: 182px !important;
    height: 45px !important;
  }

  .menu-signs-type li {
    font: normal normal normal 14px Muli;
    height: 30px;
    min-height: 30px;
    color: #4F4F4F;
  }

  .title-background-home {
    margin: 0;
    background: none;
    height: 40px;
  }

  .box-botonera {
    margin: 5px 0 10px 0 !important;
    row-gap: 11px;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    justify-content: center !important;
    align-content: center !important;
  }

  .input-certificate {
    margin-top: -11px !important;
    position: absolute;
  }

  .icon-button {
    width: 42px;
    height: 42px;
  }

  .icon-limpiar,
  .icon-anadir {
    background-size: 35px;
    margin-left: 20px !important;
  }

  .icon-button span {
    width: 50px;
    display: block;
  }

  .checkmark-title {
    margin-top: -7px !important;
    margin-left: 20px;
    position: absolute !important;
  }

  #box-icons-buttons {
    visibility: hidden;
  }

  .drag-sign img {
    height: 50% !important;
    width: auto !important;
  }
}

@media screen and (max-width: 390px) {

  .paper-sign,
  .seleccionada {
    width: 130px !important;
    height: 70px !important;
    padding: 0 !important;
  }
}