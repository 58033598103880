@import url('https://fonts.googleapis.com/css?family=Muli|Roboto&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
body {
 /* background-image: url(assets/img/fondo_login.jpg);
  background-size:'100%';
  background-repeat: no-repeat;*/
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
