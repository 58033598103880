.sign-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 440px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
  ;
}

.sign-paper-man {
  position: absolute;
  display: 'flex';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 440px;
  background: #FFFFFF;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  opacity: 1;
  padding: 0 0;
}

.sign-paper-man-center {
  position: relative;
  padding: 7px 50px;
  top: 19px !important;
  background: transparent !important;
}

.tabs-manuscrita {
  width: 110%;
  padding-top: 0px !important;
}
.message {
  padding-left: 20px !important;
  padding-right: 20px;
  padding-top: 72px;
  width: 100% !important;
  text-align: center;
  font: normal normal normal 18px/22px Muli;
  letter-spacing : 0px;
  color: #9D9D9C;
  opacity: 1
}
.signs {
  position: relative;
  font-weight: bold;
  justify-items: right !important;
  font-size: 22px;
  top: -2px !important;
  background: transparent !important;
  text-align: center !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.tabs-manuscrita .Mui-selected {
  background: transparent !important;
  text-align: center;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.sign-button-option-sel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 0px #00000033;
  border: 0.6078676581382751px solid #F5AE19;
  border-radius: 7px;
  opacity: 1;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 0px;
  color: #F5AE19;
  opacity: 1;
  font-size: 18px;
}

.title-sign-draw {
  text-align: left;
  letter-spacing: 0px;
  color: #E55200;
  opacity: 1;
  font-size: 19px;
}

.sig-pad {
  width: 480px;
  height: 250px;
  background: transparent;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.sign-buttons {
  width: 100%;
  height: 30px;
}

.sign-buttons-default {
  font-size: 9pt;
  color: #ff9800;
  height: 30px;
}

.sig-image {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.sig-image {
  width: 100%;
  height: 100%;
}

.btnResponsive {
  display: none !important;
}

.draw-sign {
  position: absolute;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: normal normal normal 18px/22px Muli;
  color: #9D9D9C;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .sign-paper-man {
    max-width: 500px;
    min-height: 240px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
  .message {
    padding-left: 7px !important;
    padding-right: 10px !important;margin-top: -10px !important;
    width: 2% !important;
    text-align: center;
    font: normal normal normal 18px/22px Muli;
    letter-spacing : 0px;
    color: #9D9D9C;
    opacity: 1
  }
  .signs {
    top: 0px !important;
  }
  #box-icons-buttons{
    margin-top:-12px;
    margin-right: 7px !important;
  }
  .mostrar {
  visibility: visible;
  margin-top: 20px !important;
  }
  .tabs-manuscrita {
    width: 109%;
    padding-top: 0px !important;
  }
  .sign-paper-man-center {
    padding: 11px 20px 0 20px;
    top: 1px !important;
  }
  .sig-pad {
    width: calc(100%);
  }
  .load-sign {
    width: calc(100%);
  }
  .drag-sign {
    width: calc(100%);
    margin-bottom: -5px !important;
  }
  .drag-sign img {
    width: calc(90%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000000;
    box-shadow: none;
  }
  .textZoneUploadSign {
    display: none !important;
  }
  .predefined-alert {
    display: none !important;
  }
  .btnResponsive {
    display: flex !important;
  }
  .sig-pad {
    width: calc(100%);
  }
  #signature-canvas {
    width: calc(100%);
    height: auto;
  }
  #selected {
    top: 6px;
    width: 100%;
  }
}
